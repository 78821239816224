import classes from './JobApplication.module.css';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dateformat from 'dateformat';

// Import images
import captcha from '../../assets/icons/get_captcha.jpeg';

// IMPORT MUI COMPONENTS
import { TextField, FormControlLabel, Checkbox } from '@mui/material/';
import dayjs from 'dayjs';
import Grid from '@mui/material/Unstable_Grid2';

// IMPORT OWN COMPONENTS
import PageHeading from '../UI/PageHeading';
import Button from '../UI/Button';
import PhoneInput from '../UI/Inputs/PhoneInput';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import PopupContext from '../../store/popup-context';
import Popup from '../UI/Popup';
import usePostContent from '../../hooks/usePostContent';
import { globals } from '../../data/global-vars';
import DateInput from '../UI/Inputs/DateInput';
import TextInput from '../UI/Inputs/TextInput';
import EmailInput from '../UI/Inputs/EmailInput';
import TextArea from '../UI/Inputs/TextArea';
import FileInput from '../UI/Inputs/FileInput';
import CheckboxInput from '../UI/Inputs/CheckboxInput';
import SelectInput from '../UI/Inputs/SelectInput';

function JobApplication() {
  const history = useHistory();
  const { setPopupMessage } = useContext(PopupContext);
  const { error, postRequest } = usePostContent();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';
  }, []);

  const tomorrow = dayjs(new Date()).format('YYYY-MM-DD');

  const [formData, setFormData] = useState({
    job: '',
    salutation: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    startDate: tomorrow,
    message: '',
    reCaptcha: '',
    acceptPrivacy: false,
  });

  const [vita, setVita] = useState(null);
  const [motivationFile, setMotivationFile] = useState(null);
  const [recommendationFile, setRecommendationFile] = useState(null);
  const [otherFiles, setOtherFiles] = useState([]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  function submitApplicationHandler(e) {
    e.preventDefault();

    const formFields = [
      { mailLabel: 'Angestrebte Tätigkeit', value: formData.job },
      { mailLabel: 'Anrede', value: formData.salutation },
      { mailLabel: 'Vorname', value: formData.firstName },
      { mailLabel: 'Nachname', value: formData.lastName },
      { mailLabel: 'Telefon', value: formData.phone },
      { mailLabel: 'E-Mail', value: formData.email },
      { mailLabel: 'Straße', value: formData.street },
      { mailLabel: 'Hausnummer', value: formData.houseNumber },
      { mailLabel: 'Postleitzahl', value: formData.postalCode },
      { mailLabel: 'Stadt', value: formData.city },
      {
        mailLabel: 'Startdatum',
        value: dateformat(new Date(formData.startDate), 'dd.mm.yyyy'),
      },
      { mailLabel: 'Nachricht', value: formData.message },
      { mailLabel: 'Datenschutz akzeptiert.', value: formData.acceptPrivacy },
      // { mailLabel: 'Einwilligung', value: formData.consent },
    ];

    const formRequest = {
      fromMail: formData.email,
      phone: formData.phone,
      firstName: formData.firstName,
      lastName: formData.lastName,
      reCaptcha: formData.reCaptcha,
      type: 'assessment',
      data: formFields,
    };
    const reqFormData = new FormData();
    reqFormData.append('data', JSON.stringify(formRequest));
    [vita, motivationFile, recommendationFile, ...otherFiles]
      .filter((obj) => obj !== undefined && obj !== null)
      .forEach((file) => {
        reqFormData.append('files', file);
      });

    postRequest(reqFormData, (d) => {
      if (!error) {
        setFormData({
          job: '',
          salutation: '',
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          street: '',
          houseNumber: '',
          postalCode: '',
          city: '',
          startDate: tomorrow,
          message: '',
          reCaptcha: '',
          acceptPrivacy: false,
        });
        setVita(null);
        setRecommendationFile(null);
        setMotivationFile(null);
        setOtherFiles([]);
        setPopupMessage('Bewerbung gesendet', 'success');
      } else setPopupMessage('Fehler beim Senden der Bewerbung', 'error');
    });
  }

  const formIsValid = () => {
    const requiredFields = [
      'firstName',
      'lastName',
      'phone',
      'email',
      'acceptPrivacy',
      'job',
      'street',
      'houseNumber',
      'postalCode',
      'city',
      'startDate',
      'message',
      'acceptPrivacy',
      'reCaptcha',
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }

    if (formData.reCaptcha !== '57A1') return false;

    return true;
  };

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.data) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          job: history.location.state.data.job,
        }));
      }
    }
  }, []);

  return (
    <div className="grid grid-cols-12 w-full max-w-[var(--max-content-width)] mx-auto px-4">
      <Popup />
      <div className="col-span-12">
        <PageHeading title={globals.PAGES.BEWERBUNG.name}>
          {globals.PAGES.BEWERBUNG.name}
        </PageHeading>
      </div>
      <h2 className={`${classes.teaserText} col-span-12 mb-8`}>
        - Ihre Bewerbung in nur 5 Minuten -
      </h2>
      <form
        onSubmit={submitApplicationHandler}
        className="col-span-12 grid grid-cols-12 gap-4 mb-4"
      >
        <SelectInput
          className="col-span-12 md:col-span-6"
          name="salutation"
          label="Anrede"
          value={formData.salutation}
          onChange={handleChange}
          options={[
            { label: 'Herr', value: 'Herr' },
            { label: 'Frau', value: 'Frau' },
            { label: 'Andere', value: 'Andere' },
          ]}
        />
        <TextInput
          className="col-span-12 md:col-span-6"
          name="firstName"
          label="Vorname"
          value={formData.firstName}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextInput
          className="col-span-12 md:col-span-6"
          name="lastName"
          label="Nachname"
          value={formData.lastName}
          onChange={handleChange}
          fullWidth
          required
        />
        <EmailInput
          className="col-span-12 md:col-span-6"
          name="email"
          label="E-Mail"
          value={formData.email}
          onChange={handleChange}
          fullWidth
        />
        <PhoneInput
          className="col-span-12 md:col-span-6"
          name="phone"
          label="Telefon"
          onChange={(e) =>
            handleChange({
              target: { name: 'phone', value: e.target.value },
            })
          }
          value={formData.phone}
          required
        />
        <hr className={`${classes.stripline} col-span-12`}></hr>
        <TextInput
          className="col-span-12 md:col-span-6"
          name="street"
          label="Straße"
          value={formData.street}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextInput
          className="col-span-12 md:col-span-6"
          name="houseNumber"
          label="Hausnummer"
          value={formData.houseNumber}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextInput
          className="col-span-12 md:col-span-6"
          name="postalCode"
          label="Postleitzahl"
          value={formData.postalCode}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextInput
          className="col-span-12 md:col-span-6"
          name="city"
          label="Stadt"
          value={formData.city}
          onChange={handleChange}
          required
        />

        <hr className={`${classes.stripline} col-span-12`}></hr>

        <TextInput
          className="col-span-12 md:col-span-6"
          name="job"
          label="Angestrebte Tätigkeit"
          value={formData.job}
          onChange={handleChange}
          required
        />
        <DateInput
          className="col-span-12 md:col-span-6"
          label="Gewünschtes Startdatum"
          disablePast
          value={formData.startDate}
          onChange={(e) =>
            handleChange({
              target: {
                name: 'startDate',
                value: e.target.value,
              },
            })
          }
          required
        />
        <TextArea
          className="col-span-12 md:col-span-6"
          name="message"
          label="Nachricht"
          value={formData.message}
          onChange={handleChange}
        />
        <hr className={`${classes.stripline} col-span-12`}></hr>
        <div className="col-span-12 grid grid-cols-12 gap-4">
          <FileInput
            label="Lebenslauf*"
            types={['PDF']}
            className="col-span-12 md:col-span-6"
            handleChange={setVita}
            required
          />
          <FileInput
            label="Motivationsschreiben*"
            types={['PDF']}
            className="col-span-12 md:col-span-6"
            handleChange={setMotivationFile}
            required
          />
          <FileInput
            label="Empfehlungsschreiben*"
            types={['PDF']}
            className="col-span-12 md:col-span-6"
            handleChange={setRecommendationFile}
            required
          />
          <FileInput
            label="Sonstige Dokumente"
            types={['PDF']}
            className="col-span-12 md:col-span-6"
            handleChange={setOtherFiles}
            required
            multiple
          />
        </div>
        <hr className={`${classes.stripline} col-span-12`}></hr>
        <CheckboxInput
          name="acceptPrivacy"
          className="col-span-12"
          label={
            <span>
              Ich habe die <a href="/datenschutz">Datenschutzerklärung</a>{' '}
              gelesen und akzeptiere diese.
            </span>
          }
          value={formData.acceptPrivacy}
          onChange={handleChange}
        />
        <div className="col-span-12 flex items-center">
          <img
            src={captcha}
            alt="captcha"
            loading="lazy"
            style={{
              maxWidth: '140px',
              marginRight: '10px',
              borderRadius: '8px',
            }}
          />
          <TextInput
            name="reCaptcha"
            label="Sicherheitscode"
            value={formData.reCaptcha}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-span-12 flex justify-end">
          <Button
            type="submit"
            maxWidth="200px"
            color="green"
            disabled={!formIsValid()}
          >
            Abschicken
          </Button>
        </div>
      </form>
    </div>
  );
}

export default JobApplication;
