function TextInput(props) {
  const { value, onChange, label, required, error, name, className } = props;

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <div className={className}>
      <div className="relative text-left h-[58px] cursor-text">
        <input
          type="text"
          value={value || ''}
          id={`text-input-${name}`}
          className="block px-2.5 pb-2.5 pt-4 w-full h-full text-sm text-gray-900 bg-transparent rounded border-1 border-gray-300 border-[1px] appearance-none peer focus:border-[var(--text-color)] focus:outline-none"
          placeholder=""
          name={name}
          onChange={handleChange}
          required={required}
        />
        <label
          htmlFor={`text-input-${name}`}
          className="cursor-text bg-white absolute text-gray-500 duration-200 transform -translate-y-4 scale-75 top-1 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-[var(--vw-color)] peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
        >
          {label} {required && '*'}
        </label>
      </div>
      {error && (
        <div className="text-red-500 text-left w-full text-sm">{error}</div>
      )}
    </div>
  );
}

export default TextInput;
