import { useContext, useEffect, useState } from 'react';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import ModalContext from '../../store/modal-context';

import Button from '../UI/Button';
import PopupContext from '../../store/popup-context';
import AuthContext from '../../store/auth-context';
import useHttp from '../../hooks/use-http';
import TextInput from '../UI/Inputs/TextInput';
import EmailInput from '../UI/Inputs/EmailInput';
import SelectInput from '../UI/Inputs/SelectInput';

const AccountForm = ({ existingUser = null, setUsers }) => {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { closeModal } = useContext(ModalContext);
  const { error, sendRequest } = useHttp();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    role: 'employee',
  });

  const [loading, setLoading] = useState(false);

  function translateError() {
    let errorMessage = null;
    if (error === 'User already exists.')
      errorMessage = 'Account existiert bereits.';
    return errorMessage;
  }

  useEffect(() => {
    if (existingUser) {
      setFormData({
        firstName: existingUser.firstName || '',
        lastName: existingUser.lastName || '',
        username: existingUser.username || '',
        email: existingUser.email || '',
        role: existingUser.role || 'employee',
      });
    }
  }, [existingUser]);

  const roleOptions = [
    { label: 'Mitarbeiter', value: 'employee' },
    { label: 'Admin', value: 'admin' },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const existingUserId = existingUser ? existingUser._id : null;
    const data = formData;
    sendRequest(
      {
        url: existingUserId ? '/api/v1/user/' + existingUserId : '/api/v1/user',
        method: existingUserId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
        noJSON: false,
        body: data,
      },
      (data) => {
        setPopupMessage(
          `Account erfolgreich ${existingUserId ? 'bearbeitet' : 'erstellt'}!`,
          'success'
        );
        closeModal();
        if (existingUserId)
          setUsers((prev) => {
            return prev.map((user) =>
              user._id === existingUserId ? data : user
            );
          });
        else setUsers((prev) => [data, ...prev]);
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} className="pb-4 grid grid-cols-12 gap-4">
      <span className="col-span-12 text-sm  text-danger">
        {translateError(error)}
      </span>
      <TextInput
        label="Vorname"
        name="firstName"
        className="col-span-12 md:col-span-6"
        value={formData.firstName}
        onChange={handleChange}
        required
      />
      <TextInput
        label="Nachname"
        name="lastName"
        className="col-span-12 md:col-span-6"
        value={formData.lastName}
        onChange={handleChange}
        required
      />
      <TextInput
        label="Benutzername"
        name="username"
        className="col-span-12 md:col-span-6"
        value={formData.username}
        onChange={handleChange}
        required
      />
      <EmailInput
        label="E-Mail"
        name="email"
        className="col-span-12 md:col-span-6"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <SelectInput
        label="Rolle"
        name="role"
        className="col-span-12 md:col-span-6"
        options={roleOptions.map((o) => ({
          label: o.label,
          value: o.value,
        }))}
        value={formData.role}
        onChange={handleChange}
        required
      />

      {/* Submit Button */}
      <div className="col-span-12 flex w-full justify-end">
        <Button color="green" type="submit" maxWidth="200px">
          {loading
            ? 'Speichern...'
            : existingUser
            ? 'Aktualisieren'
            : 'Erstellen'}
        </Button>
      </div>
    </form>
  );
};

export const UsersTable = (props) => {
  const authCtx = useContext(AuthContext);
  const users = props.users;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState(users);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const { showModal } = useContext(ModalContext);
  const { sendRequest } = useHttp();
  const { setPopupMessage } = useContext(PopupContext);

  useEffect(() => {
    const lowercasedSearch = searchTerm.toLowerCase();
    const filtered = users.filter(
      (user) =>
        user.firstName?.toLowerCase().includes(lowercasedSearch) ||
        user.lastName?.toLowerCase().includes(lowercasedSearch) ||
        user.email?.toLowerCase().includes(lowercasedSearch) ||
        user.username?.toLowerCase().includes(lowercasedSearch) ||
        user.role?.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredUsers(filtered);
  }, [searchTerm, users]);

  const toggleSelect = (id) => {
    setSelectedUsers((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const showAddForm = () => {
    return showModal(
      'Neuen Benutzer erstellen',
      null,
      <AccountForm
        onSubmit={props.handleUserSubmission}
        setUsers={props.setUsers}
      />
    );
  };

  const deleteHandler = async () => {
    const selectedUserIds = Object.entries(selectedUsers)
      .map((o) => o[1] === true && o[0])
      .filter((o) => o !== false);

    const count = selectedUserIds.length;

    if (count === 0) return;
    const confirmed = window.confirm(
      count === 1
        ? 'Möchten Sie den ausgewählten Nutzer wirklich löschen?'
        : `Möchten Sie die ${count} ausgewählten Nutzer wirklich löschen?`
    );

    if (!confirmed) return;

    sendRequest(
      {
        url: '/api/v1/user',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authCtx.token,
        },
        body: {
          userIds: selectedUserIds,
        },
      },
      (data) => {
        setPopupMessage(`Nutzer gelöscht.`, 'success');
        props.setUsers((prev) =>
          prev.filter((o) => !selectedUserIds.includes(o._id))
        );
      }
    );
  };
  return (
    <div className="mt-2 mb-4 text-left">
      <div className="grid grid-cols-12 relative overflow-hidden bg-white border rounded-lg">
        {/* Suchleiste */}
        <div className="col-span-12 px-2 grid grid-cols-12 gap-2 my-2">
          <div className="col-span-6">
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2 outline-none"
                placeholder="Suchen"
                required=""
              />
            </div>
          </div>
          <div className="col-span-6 flex justify-end gap-x-2">
            {authCtx.isAdmin &&
              Object.entries(selectedUsers).filter((o) => o[1] === true)
                .length >= 1 && (
                <button
                  className="col-span-1 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                  onClick={deleteHandler}
                >
                  Löschen
                </button>
              )}
            <button
              className="col-span-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-green-700 bg-white border border-green-700 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
              onClick={showAddForm}
            >
              + Hinzufügen
            </button>
          </div>
        </div>

        {/* Tabelle */}
        <div className="col-span-12">
          <div className="hidden lg:grid grid-cols-12 text-left gap-4 px-4 py-2 bg-gray-50 text-xs font-semibold text-gray-700 uppercase">
            <div className="col-span-4">Name</div>
            <div className="col-span-4">E-Mail</div>
            <div className="col-span-2">Rolle</div>
            <div className="col-span-2 text-right"></div>
          </div>

          <hr className="block lg:hidden col-span-12 border-t-1 border-gray-200" />

          {filteredUsers.map((user) => (
            <div
              key={user.username}
              className="grid grid-cols-12 items-center gap-4 px-4 py-3 border-b"
            >
              <div className="col-span-12 md:col-span-4 flex flex-row items-center">
                {authCtx.isAdmin && (
                  <input
                    type="checkbox"
                    value={selectedUsers[user._id]}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelect(user._id);
                    }}
                    className="mx-4 w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary cursor-pointer"
                  />
                )}
                <div className="w-full flex flex-col">
                  <div className="w-full">
                    <span>
                      {user.firstName} {user.lastName}
                    </span>{' '}
                    <br />
                    <span className="md:hidden">({user.email})</span>
                  </div>
                  <span className="adminInfo">({user.username})</span>
                </div>
              </div>
              <div className="col-span-4 hidden md:block">{user.email}</div>
              <div className="col-span-6 text-right md:col-span-2 md:text-left">
                {user.role === 'superadmin' ? (
                  <span className="rounded-md bg-warning/10 py-0.5 px-2.5 border border-transparent text-sm text-warning">
                    {user.role}
                  </span>
                ) : user.role === 'admin' ? (
                  <span className="rounded-md bg-info/10 py-0.5 px-2.5 border border-transparent text-sm text-info">
                    {user.role}
                  </span>
                ) : (
                  user.role
                )}
              </div>
              <div className="col-span-6 md:col-span-2 text-right">
                {user.role !== 'superadmin' && (
                  <button
                    className="text-blue-600 hover:underline"
                    onClick={() =>
                      showModal(
                        'Benutzer bearbeiten',
                        null,
                        <AccountForm
                          onSubmit={props.handleUserSubmission}
                          existingUser={user}
                          setUsers={props.setUsers}
                        />
                      )
                    }
                  >
                    Bearbeiten
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
