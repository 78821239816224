function CheckboxInput(props) {
  const { value, onChange, label, required, error, name, className } = props;

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <div className={`relative text-left flex items-center ${className || ''}`}>
      <div className="flex items-center h-full peer">
        <input
          type="checkbox"
          id={`checkbox-input-${name}`}
          name={name}
          checked={!!value}
          onChange={handleChange}
          required={required}
          className="w-5 h-5 text-[var(--text-color)] border-gray-300 rounded focus:ring-0 focus:outline-none cursor-pointer"
        />
        <label
          htmlFor={`checkbox-input-${name}`}
          className="ml-3 text-sm text-gray-900 select-none cursor-pointer peer-focus:text-[var(--vw-color)]"
        >
          {label} {required && '*'}
        </label>
      </div>
      {error && (
        <div className="absolute -bottom-5 left-0 text-red-500 text-sm">
          {error}
        </div>
      )}
    </div>
  );
}

export default CheckboxInput;
