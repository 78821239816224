import { Fragment, useState, useContext } from 'react';

// IMPORT OWN COMPONENTS
import PageHeading from '../UI/PageHeading';
import * as React from 'react';
import Button from '../UI/Button';
import PopupContext from '../../store/popup-context';
import PhoneInput from '../UI/Inputs/PhoneInput';

// Import images
import captcha from '../../assets/icons/get_captcha.jpeg';

// IMPORT MUI COMPONENTS
import { TextField, FormControlLabel, Checkbox } from '@mui/material/';
import Popup from '../UI/Popup';
import Grid from '@mui/material/Unstable_Grid2';
import usePostContent from '../../hooks/usePostContent';
import { globals } from '../../data/global-vars';

function Contact() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = 'scroll';
  }, []);
  const { setPopupMessage } = useContext(PopupContext);
  const { error, postRequest } = usePostContent();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
    reCaptcha: '',
    acceptPrivacy: false,
    // consent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const isFormValid = () => {
    // Überprüfe, ob alle erforderlichen Felder ausgefüllt sind
    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'subject',
      'message',
      'phone',
      'acceptPrivacy',
      // 'consent',
      'reCaptcha',
    ];
    let isAllFieldsFilled = requiredFields.every((field) => !!formData[field]);

    if (formData.reCaptcha !== '57A1') isAllFieldsFilled = false;

    return isAllFieldsFilled;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const formArray = [
      { mailLabel: 'Vorname', value: formData.firstName },
      { mailLabel: 'Nachname', value: formData.lastName },
      { mailLabel: 'Telefon', value: formData.phone },
      { mailLabel: 'E-Mail', value: formData.email },
      { mailLabel: 'Betreff', value: formData.subject },
      { mailLabel: 'Nachricht', value: formData.message },
      {
        mailLabel: 'Datenschutz',
        value:
          formData.acceptPrivacy &&
          'Die Datenschutzerklärung wurde akzeptiert.',
      },
      // {
      //   mailLabel: 'Einwilligung',
      //   value:
      //     formData.consent && 'Die Einwilligungserklärung wurde akzeptiert',
      // },
    ];

    const formRequest = {
      fromMail: formData.email,
      phone: formData.phone,
      firstName: formData.firstName,
      lastName: formData.lastName,
      reCaptcha: formData.reCaptcha,
      data: formArray,
      type: 'general',
    };
    const reqFormData = new FormData();
    reqFormData.append('data', JSON.stringify(formRequest));
    postRequest(reqFormData, (d) => {
      if (!error) {
        setPopupMessage('Anfrage gesendet', 'success');
        setFormData({
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          subject: '',
          message: '',
          reCaptcha: '',
          acceptPrivacy: false,
          // consent: false,
        });
      } else {
        setPopupMessage('Fehler beim Senden der Anfrage', 'error');
      }
    });
  };

  return (
    <Fragment>
      <Popup />
      <PageHeading title={globals.PAGES.KONTAKT.name}>
        {globals.PAGES.KONTAKT.name}
      </PageHeading>
      <form
        onSubmit={submitHandler}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          paddingTop: '20px',
        }}
        className="container"
      >
        <Grid xs={12} container spacing={2}>
          <Grid xs={12} sm={6}>
            <TextField
              name="firstName"
              label="Vorname"
              variant="outlined"
              onChange={handleChange}
              value={formData.firstName}
              fullWidth
              required
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              fullWidth
              name="lastName"
              label="Nachname"
              variant="outlined"
              onChange={handleChange}
              value={formData.lastName}
              required
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <PhoneInput
              name="phone"
              label="Telefonnummer"
              onChange={(e) =>
                handleChange({
                  target: { name: 'phone', value: e.target.value },
                })
              }
              value={formData.phone}
              required
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              fullWidth
              type="email"
              name="email"
              label="E-Mail-Adresse"
              variant="outlined"
              onChange={handleChange}
              value={formData.email}
              required
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              fullWidth
              name="subject"
              label="Betreff"
              variant="outlined"
              onChange={handleChange}
              value={formData.subject}
              required
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              fullWidth
              name="message"
              label="Nachricht"
              placeholder="Ihre Nachricht an uns..."
              variant="outlined"
              rows="7"
              multiline //erzeugt mehrzeiliges Textfeld
              onChange={handleChange}
              value={formData.message}
              required
            />
          </Grid>
          <Grid xs={12} container sx={{ textAlign: 'left' }}>
            <Grid xs={12}>
              <FormControlLabel
                label={
                  <span>
                    Ich habe die <a href="/datenschutz">Datenschutzerklärung</a>{' '}
                    gelesen und akzeptiere diese.
                  </span>
                }
                name="acceptPrivacy"
                required
                control={
                  <Checkbox
                    checked={formData.acceptPrivacy}
                    onChange={handleChange}
                  />
                }
              />
            </Grid>
            {/* <Grid xs={12}>
              <FormControlLabel
                label="Einwilligungserklärung"
                name="consent"
                required
                control={
                  <Checkbox
                    checked={formData.consent}
                    onChange={handleChange}
                  />
                }
              />
            </Grid> */}
            <Grid
              xs={12}
              container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Grid xs="auto">
                <img
                  src={captcha}
                  alt="captcha"
                  loading="lazy"
                  style={{
                    maxWidth: '140px',
                    marginRight: '10px',
                    borderRadius: '8px',
                  }}
                />
              </Grid>
              <Grid xs={4}>
                <TextField
                  fullWidth
                  label="Sicherheitscode"
                  variant="outlined"
                  name="reCaptcha"
                  sx={{ maxWidth: '150px' }}
                  value={formData.reCaptcha}
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '20px',
            }}
          >
            <Button
              type="submit"
              color="green"
              maxWidth="200px"
              disabled={!isFormValid()}
            >
              Abschicken
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

export default Contact;
