import { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';
import PageHeading from '../UI/PageHeading';
import { EmployeeList } from '../About/StaffWidgets';

// IMPORT MUI COMPONENTS
import SwipeableViews from 'react-swipeable-views';
import { Tab } from '@mui/material/';

// IMPORT DATA
import { globals } from '../../data/global-vars';

// IMPORT HOOKS AND CONTEXTS
import AuthContext from '../../store/auth-context';
import { useBrandFind } from '../../hooks/useFinders';
import { NewOpeningHoursForm } from '../About/OpeningHours';
import { useTheme } from '@emotion/react';
import { UsersTable } from '../About/Users';
import PopupContext from '../../store/popup-context';
import { StyledTabs, TabPanel } from '../UI/TabController';
import DepartmentsList from '../About/Departments';
import useHttp from '../../hooks/use-http';
import UserGroupsTable from '../About/UserGroups';
import { useThisUser, useUsers } from '../../hooks/users';
import { useEmployees } from '../../hooks/employees';
import { useAllDepartmentsGrouped } from '../../hooks/departments';

function Admin() {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const { setPopupMessage } = useContext(PopupContext);
  const { error } = useHttp();
  const history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const emptyEmployee = {
    firstName: '',
    lastName: '',
    description: '',
    birthDate: new Date(),
    edit: false,
    departments: [],
  };
  const { data: thisUser } = useThisUser();

  const emptyDepartment = {
    title: '',
    key: '',
    brand: globals.BRANDS.VW.make,
  };
  const [selectedEmployee, setSelectedEmployee] = useState(emptyEmployee);
  const [selectedDepartment, setSelectedDepartment] = useState(emptyDepartment);
  useEmployees(setEmployees);
  useUsers(setUsers);
  useAllDepartmentsGrouped(setDepartments);

  useEffect(() => {
    function translateError() {
      let errorMessage = null;
      if (error === 'User already exists.')
        errorMessage = 'Account existiert bereits.';
      return errorMessage;
    }
    if (error) setPopupMessage(translateError(error), 'danger');
  }, [error]);

  const theme = useTheme();
  const [value, setValue] = useState(0);

  function getCorrespondingUrl(index) {
    switch (index) {
      case 0:
        return 'oeffnungszeiten';

      case 1:
        return 'mitarbeiter';

      case 2:
        return 'accounts';

      case 3:
        return 'abteilungen';

      default:
        return 'oeffnungszeiten';
    }
  }

  const tabKey = params[0].split('/');
  useEffect(() => {
    if (tabKey[0] === getCorrespondingUrl(1)) {
      if (value !== 1) {
        window.scrollTo(0, 0);
        setValue(1);
      }
    } else if (tabKey[0] === getCorrespondingUrl(2)) {
      if (value !== 2) {
        window.scrollTo(0, 0);
        setValue(2);
      }
    } else if (tabKey[0] === getCorrespondingUrl(3)) {
      if (value !== 3) {
        window.scrollTo(0, 0);
        setValue(3);
      }
    } else {
      if (value !== 0) window.scrollTo(0, 0);
      setValue(0);
    }
  }, [value, tabKey]);

  function handleTabChange(event, index) {
    setValue(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/${globals.PAGES.ADMIN.url}/` + url);
  }

  function handleChangeTabIndex(index) {
    setValue(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/${globals.PAGES.ADMIN.url}/` + url);
  }

  function patchEmployees(e, action) {
    if (action === 'add') {
      setSelectedEmployee(emptyEmployee);
      setEmployees((arr) => {
        const newItemDate = new Date(e.date);
        const index = arr.findIndex(
          (item) => new Date(item.date) > newItemDate
        );
        if (index === -1) {
          return [...arr, e];
        }
        return [...arr.slice(0, index), e, ...arr.slice(index)];
      });
    } else if (action === 'edit') {
      setSelectedEmployee(emptyEmployee);
      setEmployees((arr) => {
        const index = arr.findIndex((item) => item._id === e._id);
        return index === -1
          ? arr
          : [...arr.slice(0, index), e, ...arr.slice(index + 1)];
      });
    } else if (action === 'delete') {
      setSelectedEmployee(emptyEmployee);
      setEmployees((arr) => {
        const index = arr.findIndex((item) => item._id === e._id);
        if (index !== -1) {
          arr.splice(index, 1);
        }
        return arr;
      });
    }
  }

  function patchDepartments(e, action) {
    if (action === 'add') {
      setSelectedDepartment(emptyDepartment);
      setDepartments((arr) => {
        const updatedBrands = arr.map((brand) => {
          if (brand.brandKey === e.brand) {
            return {
              ...brand,
              departments: [...brand.departments, e],
            };
          }
          return brand;
        });

        return updatedBrands;
      });
      setPopupMessage('Abteilung: "' + e.title + '" hinzugefügt.', 'success');
    } else if (action === 'edit') {
      setSelectedDepartment(emptyDepartment);
      setDepartments((arr) => {
        const updatedBrands = arr.map((brand) => {
          const index = brand.departments.findIndex(
            (item) => item._id === e._id
          );
          return {
            ...brand,
            departments:
              index === -1
                ? brand.departments
                : [
                    ...brand.departments.slice(0, index),
                    e,
                    ...brand.departments.slice(index + 1),
                  ],
          };
        });

        return updatedBrands;
      });
    } else if (action === 'delete') {
      setSelectedDepartment(emptyDepartment);
      setDepartments((arr) => {
        const updatedBrands = arr.map((brand) => {
          return {
            ...brand,
            departments: brand.departments.filter((obj) => obj._id !== e._id),
          };
        });

        return updatedBrands;
      });
    }
  }

  if (authCtx.isAdmin) {
    return (
      <div className="container">
        <PageHeading title="Admin">Administration</PageHeading>
        <StyledTabs
          value={value}
          indicatorColor={brandObject.primeColor}
          onChange={handleTabChange}
          sx={{ marginBottom: '20px' }}
        >
          <Tab label="Öffnungszeiten" />
          <Tab label="Mitarbeiter" />
          <Tab label="Accounts" />
          <Tab label="Abteilungen" />
        </StyledTabs>
        <SwipeableViews
          axis="x"
          index={value}
          onChangeIndex={handleChangeTabIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            style={{ paddingTop: '20px' }}
          >
            <NewOpeningHoursForm />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            id="mitarbeiter"
          >
            <EmployeeList
              allDepartments={departments}
              employees={employees}
              selected={selectedEmployee}
              setSelected={setSelectedEmployee}
              patchEmployees={patchEmployees}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction} id="accounts">
            <h1>Accounts</h1>
            <UsersTable error={error} users={users} setUsers={setUsers} />
            <h1>Nutzergruppen</h1>
            <UserGroupsTable error={error} users={users} setUsers={setUsers} />
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            dir={theme.direction}
            id="abteilungen"
          >
            <DepartmentsList
              departments={departments}
              selected={selectedDepartment}
              setSelected={setSelectedDepartment}
              patchDepartments={patchDepartments}
            />
          </TabPanel>
        </SwipeableViews>
        <div
          className="flex flex-row justify-between items-end"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <div className="w-full px-4 flex flex-row justify-between items-end">
            <div className="flex flex-col text-left adminInfo pr-2 gap-0">
              {`Eingeloggt als ${thisUser.firstName} ${thisUser.lastName}`}
              <br />
              {`(${thisUser.email})`}
            </div>

            <Button
              onClick={() => authCtx.logout()}
              color="red"
              maxWidth="200px"
            >
              Ausloggen
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Redirect
        to={`/${globals.BRANDS.VW.url}/${globals.PAGES.STARTSEITE.url}/`}
      />
    );
  }
}

export default Admin;
