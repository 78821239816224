function TextArea(props) {
  const {
    value,
    onChange,
    label,
    required,
    error,
    name,
    rows = 4,
    className,
  } = props;

  const handleChange = (event) => {
    onChange(event);
  };

  const isEmpty = !value;

  return (
    <div className={className}>
      <div className="relative text-left h-auto">
        <textarea
          value={value || ''}
          id={`textarea-input-${name}`}
          name={name}
          rows={rows}
          onChange={handleChange}
          required={required}
          className="block px-2.5 pb-2.5 pt-6 w-full text-sm text-gray-900 bg-transparent rounded border-[1px] border-gray-300 appearance-none peer focus:border-[var(--text-color)] focus:outline-none resize-none"
        />
        <label
          htmlFor={`textarea-input-${name}`}
          className={`absolute text-gray-500 duration-200 transform z-10 origin-[0] px-2 bg-white start-1
            ${
              isEmpty
                ? 'top-4 text-base scale-100 translate-y-0'
                : 'top-1 text-sm -translate-y-4 scale-75'
            }
            peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:text-[var(--vw-color)]
          `}
        >
          {label} {required && '*'}
        </label>
      </div>
      {error && (
        <div className="text-red-500 text-left w-full text-sm">{error}</div>
      )}
    </div>
  );
}

export default TextArea;
