import classes from './Request.module.css';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import dateformat from 'dateformat';

// IMPORT OWN COMPONENTS
import PageHeading from '../UI/PageHeading';

// Import images
import captcha from '../../assets/icons/get_captcha.jpeg';

// IMPORT MUI AND OTHER COMPONENTS
import { Tab, FormControlLabel, Checkbox } from '@mui/material/';
import { useTheme } from '@emotion/react';
import { requests } from '../../data/service';
import SwipeableViews from 'react-swipeable-views';
import Button from '../UI/Button';
import dayjs from 'dayjs';
import PopupContext from '../../store/popup-context';
import Popup from '../UI/Popup';
import usePostContent from '../../hooks/usePostContent';
import { StyledTabs, TabPanel } from '../UI/TabController';
import { useBrandFind } from '../../hooks/useFinders';
import { compData, globals } from '../../data/global-vars';
import PhoneInput from '../UI/Inputs/PhoneInput';
import TextInput from '../UI/Inputs/TextInput';
import EmailInput from '../UI/Inputs/EmailInput';
import TextArea from '../UI/Inputs/TextArea';
import SelectInput from '../UI/Inputs/SelectInput';
import DateTimeInput from '../UI/Inputs/DateTimeInput';
import DateInput from '../UI/Inputs/DateInput';

function Request() {
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const brandHouse = useBrandFind(brand, 'house');
  const history = useHistory();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const { pathname } = useLocation();
  const tabKey = pathname.split('/');
  const { setPopupMessage } = useContext(PopupContext);
  const { error, postRequest } = usePostContent();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';
  }, []);

  const [formValues, setFormValues] = useState({});

  const handleFormChange = (stepIndex, requestIndex, inputIndex, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [`${requestIndex}-${stepIndex}-${inputIndex}`]: value,
    }));
  };

  function pageIsValid(page) {
    const step = page - 1;
    let feedback = true;
    if (page > 0)
      requests[value].steps[step].map((ip, id) => {
        if (ip.obligatory) {
          if (
            ip.type === 'reCaptcha' &&
            formValues[`${value}-${step}-${id}`] !== '57A1'
          ) {
            feedback = false;
          } else if (
            !formValues[`${value}-${step}-${id}`] ||
            formValues[`${value}-${step}-${id}`] === ''
          ) {
            feedback = false;
          }
        }
      });
    return feedback;
  }
  function formIsValid() {
    let feedback = true;
    requests[value].steps.map((s, i) => {
      feedback = pageIsValid(i + 1);
    });
    return feedback;
  }

  function submitHandler(e) {
    e.preventDefault();
    let formData = [];
    let genInfo = {};

    requests.map((r, i) => {
      r.steps.map((s, ii) => {
        s.map((ip, iii) => {
          const val = formValues[`${value}-${ii}-${iii}`] || '';
          if (i === value) {
            if (
              ip.type === 'date_future' ||
              ip.type === 'date_past' ||
              ip.type === 'date_time_future'
            )
              formData = [
                ...formData,
                {
                  mailLabel: ip.mailLabel,
                  value: dateformat(new Date(val), 'dd.mm.yyyy, H:MM') + ' Uhr',
                },
              ];
            else
              formData = [...formData, { mailLabel: ip.mailLabel, value: val }];

            if (
              ip.key === 'firstName' ||
              ip.key === 'lastName' ||
              ip.key === 'fromMail' ||
              ip.key === 'phone' ||
              ip.key === 'reCaptcha'
            ) {
              genInfo = { ...genInfo, [ip.key]: val };
            }
            if (ip.key === 'type')
              genInfo = {
                ...genInfo,
                type: requests[i].steps[ii].find((obj) => obj.key === 'type')
                  .value,
              };
          }
        });
      });
    });

    const formRequest = {
      ...genInfo,
      data: formData,
      type: 'service',
      reCaptcha: '57A1',
    };

    const reqFormData = new FormData();
    reqFormData.append('data', JSON.stringify(formRequest));

    postRequest(reqFormData, (d) => {
      if (!error) {
        setPopupMessage('Anfrage gesendet', 'success');
        setFormValues({});
      } else {
        setPopupMessage('Fehler beim Senden der Anfrage', 'error');
      }
    });
  }

  useEffect(() => {
    requests.map((r, i) => {
      if (tabKey[3] === r.path) {
        if (value !== i) {
          window.scrollTo(0, 0);
          setValue(i);
        }
      }
    });
  }, [value, tabKey]);

  function handleTabChange(event, index) {
    setValue(index);
    const url = requests[index] && requests[index].path;
    history.push(`/${brandObject.url}/anfrage/` + url);
  }

  function handleChangeTabIndex(index) {
    setValue(index);
    const url = requests[index] && requests[index].path;
    history.push(`/${brandObject.url}/anfrage/` + url);
  }

  function toggleStringInArray(array, string) {
    const index = array.indexOf(string);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(string);
    }
    return array;
  }

  function renderInput(ip, stepIndex, inputIndex, requestIndex) {
    const inputKey = `${requestIndex}-${stepIndex}-${inputIndex}`;
    const value = formValues[inputKey] || '';

    console.log(ip);

    switch (ip.type) {
      case 'select':
      case 'dependant_select':
        return (
          <SelectInput
            label={ip.title}
            value={value}
            name={ip.key}
            options={ip.options.map((o, oi) => ({
              label: o,
              value: o,
            }))}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'text':
        return (
          <TextInput
            label={ip.title}
            value={value}
            name={ip.key}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'email':
        return (
          <EmailInput
            label={ip.title}
            required={ip.obligatory}
            name={ip.key}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'telephone':
        return (
          <PhoneInput
            label={ip.title}
            required={ip.obligatory}
            name={ip.key}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'date_future':
      case 'date_past':
        var tomorrow = new Date();
        if ('date_future') tomorrow.setDate(tomorrow.getDate() + 1);
        else if ('date_past') tomorrow.setDate(tomorrow.getDate() - 1);
        return (
          <DateInput
            disableFuture={ip.type === 'date_future' ? false : true}
            disablePast={ip.type === 'date_past' ? false : true}
            label={ip.title}
            value={dayjs(value || tomorrow).format('YYYY-MM-DD')}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                new Date(e.target.value)
              )
            }
          />
        );

      case 'date_time_future':
      case 'date_time_past':
        var tomorrow = new Date();
        if ('date_time_future') tomorrow.setDate(tomorrow.getDate() + 1);
        else if ('date_time_past') tomorrow.setDate(tomorrow.getDate() - 1);
        return (
          <DateTimeInput
            disableFuture={ip.type === 'date_time_future' ? false : true}
            disablePast={ip.type === 'date_time_past' ? false : true}
            label={ip.title}
            value={dayjs(value || tomorrow).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                new Date(e.target.value)
              )
            }
          />
        );

      case 'textarea':
        return (
          <TextArea
            label={ip.title}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!value}
                onChange={(e) => {
                  handleFormChange(
                    stepIndex,
                    requestIndex,
                    inputIndex,
                    e.target.checked
                  );
                }}
              />
            }
            label={ip.label}
            className={classes.appointmentCheckbox}
            required={ip.obligatory === true ? true : false}
            style={{ color: 'var(--text-color)' }}
          />
        );

      case 'checkboxes':
        if (!value) handleFormChange(stepIndex, requestIndex, inputIndex, []);
        return ip.options.map((o, oi) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={value.includes(o) || false}
                  onChange={() => {
                    handleFormChange(
                      stepIndex,
                      requestIndex,
                      inputIndex,
                      toggleStringInArray(value, o)
                    );
                  }}
                />
              }
              label={o}
              key={oi}
              className={classes.appointmentCheckbox}
              style={{ color: 'var(--text-color)' }}
            />
          );
        });

      case 'reCaptcha':
        return (
          <div className="flex flex-row items-center">
            <img
              src={captcha}
              alt="captcha"
              loading="lazy"
              style={{
                maxWidth: '140px',
                marginRight: '10px',
                borderRadius: '8px',
              }}
            />
            <TextInput
              label="Sicherheitscode"
              name={ip.key}
              value={value}
              onChange={(e) => {
                handleFormChange(
                  stepIndex,
                  requestIndex,
                  inputIndex,
                  e.target.value
                );
              }}
            />
          </div>
        );

      default:
        break;
    }
  }

  return (
    <>
      <Popup />
      <PageHeading title="Anfrage">Anfrage</PageHeading>
      <div className="bg-gray-100 py-4 mb-8">
        <div
          style={{ maxWidth: 'var(--max-content-width)' }}
          className="mx-auto"
        >
          <h1 className="subheading mx-2 col-span-12" id="termin-buchen">
            Service-Termin buchen
          </h1>
        </div>
        <div className="grid grid-cols-12 w-full max-w-[600px] mx-auto my-4 rounded-lg shadow-md bg-white">
          {brandHouse === compData.SEYFARTH ? (
            <>
              <a
                href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DEU&dealer=75361&style=VW&tenant=vw"
                target="noopener noreferrer"
                className="col-span-3 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
              >
                <img src={globals.BRANDS.VW.logo} alt="Icon" className="w-14" />
              </a>
              <a
                href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DEU&dealer=75361&style=VWN&tenant=vwn"
                target="noopener noreferrer"
                className="col-span-3 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
              >
                <img
                  src={globals.BRANDS.VW_NFZ.logo}
                  alt="Icon"
                  className="w-16"
                />
              </a>
              <a
                href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DEU&dealer=75361&style=AUDI&tenant=audi"
                target="noopener noreferrer"
                className="col-span-3 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
              >
                <img
                  src={globals.BRANDS.AUDI.logo}
                  alt="Icon"
                  className="w-16"
                />
              </a>
              <a
                href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DE&dealer=75361&kvps=DEU75361V&brand=F&brandCode=F&vehicleAddedInSBO=true&driver=false&style=DEFAULT&tenant=dxone"
                target="noopener noreferrer"
                className="col-span-3 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
              >
                <span>
                  Andere <br /> Marke
                </span>
              </a>
            </>
          ) : (
            <>
              <a
                href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DEU&dealer=57371&style=VW&tenant=vw"
                target="noopener noreferrer"
                className="col-span-6 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
              >
                <img
                  src={globals.BRANDS.SKODA.logo}
                  alt="Icon"
                  className="w-22"
                />
              </a>
              <a
                href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DE&dealer=57371&kvps=DEU57371V&brand=F&brandCode=F&vehicleAddedInSBO=true&driver=false&style=DEFAULT&tenant=dxone"
                target="noopener noreferrer"
                className="col-span-6 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
              >
                <span>
                  Andere <br /> Marke
                </span>
              </a>
            </>
          )}
        </div>
      </div>
      <div style={{ maxWidth: 'var(--max-content-width)' }} className="mx-auto">
        <h1 className="subheading mx-2 col-span-12" id="termin-buchen">
          Anderen Termin buchen
        </h1>
      </div>
      <div className={`${classes.tabBar} container`} style={{ paddingTop: 0 }}>
        <StyledTabs
          value={value}
          indicatorColor={brandObject.primeColor}
          className={classes.tabWrapper}
          onChange={handleTabChange}
        >
          {requests.map((r, i) => {
            return (
              <Tab label={r.title} key={i} style={{ cursor: 'pointer' }} />
            );
          })}
        </StyledTabs>
      </div>
      <form onSubmit={submitHandler}>
        <SwipeableViews
          axis="x"
          index={value}
          className="container"
          onChangeIndex={handleChangeTabIndex}
        >
          {requests.map((r, i) => {
            return (
              <TabPanel
                value={value}
                key={i}
                index={i}
                dir={theme.direction}
                id={r.path}
              >
                {r.steps.map((s, ii) => {
                  return (
                    <div
                      key={`${i}.${ii}`}
                      className={`w-full grid grid-cols-12 gap-4 my-5 py-5 ${classes.inputSegment}`}
                    >
                      {s.map((ip, id) => {
                        const inputKey = `${ii}-${id}`;
                        return (
                          <div
                            key={inputKey}
                            className={`col-span-12 md:col-span-6`}
                          >
                            {renderInput(ip, ii, id, i)}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
                <div className="col-span-12 flex justify-end">
                  <Button
                    maxWidth="200px"
                    color="green"
                    type="submit"
                    disabled={!formIsValid()}
                  >
                    Absenden
                  </Button>
                </div>
              </TabPanel>
            );
          })}
        </SwipeableViews>
      </form>
    </>
  );
}

export default Request;
