import { Fragment, useContext, useEffect, useState } from 'react';
import useFetchContent from '../../hooks/useFetchContent';
import classes from './Events.module.css';
import dateformat from 'dateformat';
import ModalContext from '../../store/modal-context';
import AuthContext from '../../store/auth-context';
import Button from '../UI/Button';
import {
  faCancel,
  faCopy,
  faList,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import { Skeleton } from '@mui/material';
import usePatchContent from '../../hooks/usePatchContent';
import usePostContent from '../../hooks/usePostContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupContext from '../../store/popup-context';
import useDeleteContent from '../../hooks/useDeleteContent';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import Animation from '../UI/Animation';
import SingleText from '../UI/SingleText';
import DateTimeInput from '../UI/Inputs/DateTimeInput';
import TextInput from '../UI/Inputs/TextInput';
const parse = require('html-react-parser'); // must be last import

// error message for adding a new shortcut
const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

function EventsForm(props) {
  const {
    isLoading: isUploading,
    error: uploadError,
    postEvent,
  } = usePostContent();
  const {
    isLoading: isUpdating,
    error: updateError,
    patchEvent,
  } = usePatchContent();
  const { setPopupMessage } = useContext(PopupContext);
  const { updateHeight } = useContext(ModalContext);

  const [e, sE] = useState(props.selectedEvent);
  useEffect(() => {
    sE(props.selectedEvent);
  }, [props.selectedEvent]);

  const [add, setAdd] = useState(e.edit !== true);
  const [title, setTitle] = useState(e.title);
  const [location, setLocation] = useState(e.location);
  const [text, setText] = useState(e.text);
  const [date, setDate] = useState(dayjs(e.date).format('YYYY-MM-DDTHH:mm'));

  useEffect(() => {
    setTitle(e.title);
    setLocation(e.location);
    setText(e.text);
    setDate(dayjs(e.date).format('YYYY-MM-DDTHH:mm'));
    setAdd(e.edit !== true);
  }, [e]);

  function handleSubmit() {
    const event = {
      title: title,
      location: location,
      date: date,
      text: text,
    };
    if (add) {
      postEvent(event, (d) => {
        if (!uploadError) {
          props.patchEventsArray(d, 'add');
          setPopupMessage('Event erfolgreich hinzugefügt!', 'success');
        }
      });
    } else {
      patchEvent(e._id, event, (d) => {
        if (!updateError) {
          props.patchEventsArray(d, 'edit');
          setPopupMessage('Event erfolgreich bearbeitet!', 'success');
        }
      });
    }
  }

  if (isUploading || isUpdating) {
    return <PageLoaderIcon isLoading={isUpdating || isUploading} />;
  } else
    return (
      <form onSubmit={handleSubmit} className="w-full grid grid-cols-12 gap-4">
        <span className="col-span-12">{updateError && errorMessage}</span>
        <span className="col-span-12">{uploadError && errorMessage}</span>
        <TextInput
          label="Titel"
          className="col-span-12"
          name="title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <div className="col-span-12">
          <CKEditor
            editor={Editor}
            data={text}
            onChange={(e, editor) => {
              const text = editor.getData();
              setText(text);
              updateHeight();
            }}
          />
        </div>
        <TextInput
          label="Veranstaltungsort"
          name="location"
          className="col-span-12"
          variant="outlined"
          fullWidth
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          required
        />
        <DateTimeInput
          label="Datum"
          className="col-span-12"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
        />
        <div className="col-span-12 flex justify-end">
          <Button
            color="green"
            maxWidth="200px"
            type="submit"
            disabled={title === '' ? true : false}
          >
            {add ? 'Hinzufügen' : 'Bearbeiten'}
          </Button>
        </div>
      </form>
    );
}

function EventsListLine(props) {
  const e = props.event;
  const { deleteEvent } = useDeleteContent();
  const { error, setPopupMessage } = useContext(PopupContext);

  function deleteEventHandler() {
    deleteEvent(e._id, (d) => {
      if (!error) {
        props.patchEventsArray(d, 'delete');
      } else setPopupMessage('Fehler beim Löschen!', 'error');
    });
  }

  return (
    <li
      className={`${classes.editListItem} ${
        new Date(e.date) > new Date() && 'border-1 border-[1px] border-skoda'
      } rounded`}
    >
      <span>{e.title + ', '}</span>
      <span style={{ color: 'gray', marginLeft: '4px' }}>
        {dateformat(new Date(e.date), 'dd.mm.yyyy')}, {e.location}
      </span>
      <div className={classes.editListIcons}>
        <FontAwesomeIcon
          icon={faPencil}
          className="manageColor"
          onClick={() => {
            props.setSelectedEvent({ ...e, edit: true });
          }}
        />
        <FontAwesomeIcon
          icon={faCopy}
          className={classes.editListCopyIcon}
          onClick={() => {
            props.setSelectedEvent({ ...e, edit: false });
          }}
        />
        <FontAwesomeIcon
          icon={faCancel}
          className="cancelColor"
          onClick={deleteEventHandler}
        />
      </div>
    </li>
  );
}

function EventsList(props) {
  const prevEvents = props.events;
  return (
    <Fragment>
      <h2>Kommende Events</h2>
      <ul className={classes.editList}>
        {props.events.map((e, i) => {
          if (new Date(e.date) >= new Date())
            return (
              <EventsListLine
                event={e}
                key={i}
                setSelectedEvent={props.setSelectedEvent}
                patchEventsArray={props.patchEventsArray}
              />
            );
        })}
      </ul>
      <h2>Vergangene Events</h2>
      <ul className={classes.editList}>
        {prevEvents.map((e, i) => {
          if (new Date(e.date) < new Date() && i < 50)
            // only show limited amount of old events
            return (
              <EventsListLine
                event={e}
                key={i}
                setSelectedEvent={props.setSelectedEvent}
                patchEventsArray={props.patchEventsArray}
              />
            );
        })}
      </ul>
    </Fragment>
  );
}

function EventsManager(props) {
  const emptyEvent = {
    title: '',
    location: '',
    date: new Date(),
    text: '',
    edit: false,
  };
  const [selectedEvent, setSelectedEvent] = useState(emptyEvent);
  const [events, setEvents] = useState(props.events);
  const { setPopupMessage } = useContext(PopupContext);

  function patchEventsArray(e, action) {
    if (action === 'add') {
      setSelectedEvent(emptyEvent);
      props.setEvents((arr) => {
        const newItemDate = new Date(e.date);
        const index = arr.findIndex(
          (item) => new Date(item.date) > newItemDate
        );
        if (index === -1) {
          setEvents([...arr, e]);
          return [...arr, e];
        }
        setEvents([...arr.slice(0, index), e, ...arr.slice(index)]);
        return [...arr.slice(0, index), e, ...arr.slice(index)];
      });
    } else if (action === 'edit') {
      setSelectedEvent(emptyEvent);
      props.setEvents((arr) => {
        const index = arr.findIndex((item) => item._id === e._id);
        setEvents(
          index === -1
            ? arr
            : [...arr.slice(0, index), e, ...arr.slice(index + 1)]
        );
        return index === -1
          ? arr
          : [...arr.slice(0, index), e, ...arr.slice(index + 1)];
      });
    } else {
      setSelectedEvent(emptyEvent);
      props.setEvents((arr) => {
        const index = arr.findIndex((item) => item._id === e._id);
        if (index !== -1) {
          arr.splice(index, 1);
        }
        setEvents(arr);
        return arr;
      });
      setPopupMessage('Event erfolgreich entfernt.', 'success');
    }
  }

  return (
    <Fragment>
      <EventsForm
        selectedEvent={selectedEvent}
        patchEventsArray={patchEventsArray}
      />
      <EventsList
        events={events}
        patchEventsArray={patchEventsArray}
        setSelectedEvent={setSelectedEvent}
      />
    </Fragment>
  );
}

function Event(props) {
  const { showModal } = useContext(ModalContext);
  const e = props.event;
  return (
    <li>
      {e.title}{' '}
      <span style={{ color: 'gray' }}>
        - {dateformat(new Date(e.date), 'dd.mm.yyyy')} - {e.location} -
      </span>
      <span
        style={{ color: 'blue', cursor: 'pointer' }}
        onClick={() => {
          showModal(
            e.title,
            null,
            <Animation dir="left" className="pb-4">
              {parse(e.text)}
            </Animation>
          );
        }}
      >
        {' '}
        mehr Infos...
      </span>
    </li>
  );
}

function Events() {
  const { isLoading, error, getAllEvents } = useFetchContent();
  const [events, setEvents] = useState([]);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const authCtx = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);

  // fetch events
  useEffect(() => {
    getAllEvents((d) => {
      if (!error) {
        setEvents(d);
        if (!eventsLoaded) setEventsLoaded(true);
      }
    });
  }, []);

  function manageEventsHandler() {
    showModal(
      'Events bearbeiten',
      null,
      <EventsManager events={events} setEvents={setEvents} />
    );
  }

  return (
    <div style={{ marginBottom: '20px' }}>
      <h1 className="sectionHeading" id="veranstaltungen">
        Geplante Veranstaltungen
      </h1>
      {isLoading || !eventsLoaded || error ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {Array.from({ length: 3 }).map((v, i) => {
            return <Skeleton key={i} height="60px" width="90%" />;
          })}
        </div>
      ) : !events.find((obj) => {
          return new Date(obj.date) >= new Date();
        }) ? (
        <SingleText id="6481d94b12d62e7117a46cd0" field="text" />
      ) : (
        <ul className="standardText" style={{ lineHeight: '2' }}>
          {events.map((e, i) => {
            if (new Date(e.date) >= new Date())
              return <Event event={e} key={i} />;
            else return null;
          })}
        </ul>
      )}
      {authCtx.isAdmin && eventsLoaded && (
        <Button
          icon={faList}
          color="darkred"
          maxWidth="200px"
          onClick={manageEventsHandler}
        >
          VERWALTEN
        </Button>
      )}
    </div>
  );
}

export default Events;
