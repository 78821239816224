import classes from './NewsWidgets.module.css';
import { useContext, useEffect, useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Mousewheel, Scrollbar } from 'swiper';

// IMPORT OTHER COMPONENTS
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

// IMPORT OWN COMPONENTS
import AuthContext from '../../store/auth-context';
import Button from '../UI/Button';
import PageLoaderIcon from '../UI/PageLoaderIcon';

// IMPORT MUI COMPONENTS
import styled from '@mui/material/styles/styled';
import { Box, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

// IMPORT HOOKS, CONTEXTS AND FUNCTIONS
import dateformat from 'dateformat';
import useFetchContent from '../../hooks/useFetchContent';
import usePatchContent from '../../hooks/usePatchContent';
import usePostContent from '../../hooks/usePostContent';
import useDeleteContent from '../../hooks/useDeleteContent';
import PopupContext from '../../store/popup-context';
import ModalContext from '../../store/modal-context';
import Animation from '../UI/Animation';
import useHttp from '../../hooks/use-http';
import { getApiUrl } from '../../config/api';
import DateInput from '../UI/Inputs/DateInput';
import FileInput from '../UI/Inputs/FileInput';
import TextInput from '../UI/Inputs/TextInput';
import { compData } from '../../data/global-vars';
import CheckboxInput from '../UI/Inputs/CheckboxInput';
import { useBrandFind } from '../../hooks/useFinders';
import { useParams } from 'react-router-dom';
const parse = require('html-react-parser'); // must be last import

// STYLE MUI COMPONENTS
const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

// FORM FOR CREATING A NEW NEWS
function NewNewsForm(props) {
  const { setPopupMessage } = useContext(PopupContext);
  const { error, isLoading, postNews, postNewsThumbnail } = usePostContent();
  const { updateHeight } = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  // declare variables for news
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [body, setBody] = useState('');
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  const [newImage, setNewImage] = useState(null);
  const [comps, setComps] = useState([]);
  function handleSubmit(e) {
    e.preventDefault();
    const news = {
      title,
      date,
      text: body,
      comps: comps,
    };
    postNews(news, (d) => {
      if (newImage) {
        const formData = new FormData();
        formData.append('upload', newImage);
        postNewsThumbnail(formData, d._id, (i) => {
          props.patchNews({ ...d, image: { src: i.url } });
        });
      } else {
        props.patchNews(d);
      }
      setPopupMessage('News: "' + d.title + '" hochgeladen.', 'success');
    });
  }

  const { sendRequest } = useHttp();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append('upload', file);
            sendRequest(
              {
                url: '/api/v1/news/inlineimage',
                method: 'POST',
                headers: {
                  Authorization: 'Bearer ' + authCtx.token,
                },
                body: formData,
                noJSON: true,
              },
              (data) => {
                resolve({
                  default: getApiUrl(data.url),
                });
              }
            );
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <StyledBox>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PageLoaderIcon isLoading={isLoading} />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="w-full overflow-hidden grid grid-cols-12 gap-4 my-2 pb-2"
        >
          <div className="col-span-12">{error && errorMessage}</div>
          <div className="col-span-12">
            <TextInput
              label="Titel"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <DateInput
              label="Datum"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <FileInput
              label={
                !newImage ? 'Titelbild hinzufügen' : 'Titelbild ausgewählt'
              }
              name="image"
              types={fileTypes}
              handleChange={setNewImage}
              value={newImage}
            />
          </div>
          <div className="col-span-12">
            <CKEditor
              editor={Editor}
              data={body}
              onChange={(e, editor) => {
                const text = editor.getData();
                setBody(text);
                updateHeight();
              }}
              config={{
                extraPlugins: [uploadPlugin],
              }}
            />
          </div>
          <div className="col-span-12">
            {Object.keys(compData).map((c) => {
              return (
                <CheckboxInput
                  key={compData[c].NEWS_KEY}
                  name={compData[c].NEWS_KEY}
                  className="w-full my-2"
                  onChange={(e) => {
                    setComps((prev) => {
                      if (e.target.checked) {
                        return [...prev, compData[c].NEWS_KEY];
                      } else {
                        return prev.filter(
                          (item) => item !== compData[c].NEWS_KEY
                        );
                      }
                    });
                  }}
                  value={comps.includes(compData[c].NEWS_KEY)}
                  label={compData[c].PAGE_TITLE}
                />
              );
            })}
          </div>
          <div className="col-span-12 text-right">
            <Button
              type="submit"
              disabled={!newImage && title === ''}
              maxWidth="200px"
            >
              Hochladen
            </Button>
          </div>
        </form>
      )}
    </StyledBox>
  );
}

// FORM FOR EDITING AN EXISTING NEWS
function EditNewsForm(props) {
  const { setPopupMessage } = useContext(PopupContext);
  const { updateHeight } = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  // declare variables for news
  const n = props.data;
  const [title, setTitle] = useState(n.title);
  // const [subtitle, setSubtitle] = useState(n.subtitle);
  const [date, setDate] = useState(dayjs(n.date).format('YYYY-MM-DD'));
  const [body, setBody] = useState(n.text);
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  const [newImage, setNewImage] = useState(null);
  const [comps, setComps] = useState(n.comps || []);
  // implement hooks
  const { error: userError, getSingleUserById } = useFetchContent();
  const { isLoading, error, patchNews } = usePatchContent();
  const { postNewsThumbnail } = usePostContent();

  // fetch username for admin info
  const [user, setUser] = useState({ username: '', role: '' });
  useEffect(() => {
    if (authCtx.isAdmin) {
      getSingleUserById(n.editedBy, (d) => {
        if (!userError) setUser({ username: d.username, role: d.role });
      });
    }
  }, [getSingleUserById, userError, n.editedBy, authCtx.isAdmin]);

  function handleSubmit() {
    const news = {
      title,
      date,
      text: body,
      comps: comps,
    };
    if (authCtx.isAdmin) {
      patchNews(n._id, news, (d) => {
        if (!error) {
          const formData = new FormData();
          formData.append('upload', newImage);
          if (newImage) {
            postNewsThumbnail(formData, d._id, (i) => {
              props.patchNews({ ...d, image: { src: i.url } });
            });
          } else {
            props.patchNews(d);
          }
          setPopupMessage('News: "' + d.title + '" aktualisiert.', 'success');
        } else
          setPopupMessage(
            'Fehler: "' + d.title + '" wurde nicht aktualisiert.',
            'error'
          );
      });
    }
  }

  const { sendRequest } = useHttp();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append('upload', file);
            sendRequest(
              {
                url: '/api/v1/news/inlineimage',
                method: 'POST',
                headers: {
                  Authorization: 'Bearer ' + authCtx.token,
                },
                body: formData,
                noJSON: true,
              },
              (data) => {
                resolve({
                  default: getApiUrl(data.url),
                });
              }
            );
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <StyledBox>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <PageLoaderIcon isLoading={isLoading} />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="w-full overflow-hidden grid grid-cols-12 gap-4 my-2 pb-2"
        >
          <div className="col-span-12">{error && errorMessage}</div>
          <div className="col-span-12">
            <TextInput
              label="Titel"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <DateInput
              label="Datum"
              name="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <FileInput
              label={
                !newImage ? 'Titelbild hinzufügen' : 'Titelbild ausgewählt'
              }
              name="image"
              types={fileTypes}
              handleChange={setNewImage}
              value={newImage}
            />
          </div>
          <div className="col-span-12">
            <CKEditor
              editor={Editor}
              data={body}
              onChange={(e, editor) => {
                const text = editor.getData();
                setBody(text);
                updateHeight();
              }}
              config={{
                extraPlugins: [uploadPlugin],
              }}
            />
          </div>
          <div className="col-span-12">
            {Object.keys(compData).map((c) => {
              return (
                <CheckboxInput
                  key={compData[c].NEWS_KEY}
                  name={compData[c].NEWS_KEY}
                  className="w-full my-2"
                  onChange={(e) => {
                    setComps((prev) => {
                      if (e.target.checked) {
                        return [...prev, compData[c].NEWS_KEY];
                      } else {
                        return prev.filter(
                          (item) => item !== compData[c].NEWS_KEY
                        );
                      }
                    });
                  }}
                  value={comps.includes(compData[c].NEWS_KEY)}
                  label={compData[c].PAGE_TITLE}
                />
              );
            })}
          </div>
          <div className="col-span-12">
            {user.username !== '' && (
              <div className="w-full">
                <Box sx={{ textAlign: 'left', width: '100%' }}>
                  <span className="adminInfo">
                    {'Zuletzt bearbeitet: ' +
                      user.username +
                      ' (' +
                      user.role +
                      ')' +
                      ', ' +
                      dateformat(new Date(n.createdAt), 'dd.mm.yyyy') +
                      ' um ' +
                      dateformat(new Date(n.createdAt), 'H:MM:ss') +
                      ' Uhr'}
                  </span>
                </Box>
              </div>
            )}
          </div>
          <div className="col-span-12 text-right">
            <Button type="submit" disabled={title === ''} maxWidth="200px">
              Hochladen
            </Button>
          </div>
        </form>
      )}
    </StyledBox>
  );
}

// A SINGLE NEWS WIDGET
function NewsWidget(props) {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error: delError, deleteNews } = useDeleteContent();

  // declare data variables
  const n = props.data;

  // fetch user name
  const [user, setUser] = useState({ username: '', role: '' });
  const { error, getSingleUserById } = useFetchContent();

  useEffect(() => {
    if (authCtx.isAdmin) {
      getSingleUserById(n.editedBy, (d) => {
        if (!error) setUser({ username: d.username, role: d.role });
      });
    }
  }, [getSingleUserById, error, n, authCtx.isAdmin]);
  function showModalHandler() {
    if (n.text !== '')
      showModal(
        parse(n.title),
        dateformat(new Date(n.date), 'd.m.yyyy'),
        <div className={`${classes.newsContent} pb-4`}>
          <Animation dir="left">
            {parse(
              n.text.replace(
                /<a\s+([^>]*)>/g,
                '<a $1 target="_blank" rel="noopener noreferrer">'
              )
            )}
          </Animation>
        </div>
      );
  }

  function deleteNewsHandler() {
    if (window.confirm(`Möchten Sie die News "${n.title}" wirklich löschen?`)) {
      if (authCtx.isAdmin) {
        deleteNews(n._id, () => {
          if (!delError) {
            setPopupMessage('News: "' + n.title + '" gelöscht.', 'success');
          } else {
            setPopupMessage(
              'Fehler: "' + n.title + '" wurde nicht gelöscht.',
              'error'
            );
          }
        });
      }
    }
  }

  function editNews() {
    showModal(
      'Bearbeiten: ' + parse(n.title),
      dateformat(new Date(n.date), 'd.m.yyyy'),
      <EditNewsForm data={n} patchNews={props.patchNews} />
    );
  }

  return (
    <Animation className="relative !max-h-[500px]">
      <div
        className="flex justify-center gap-y-8 flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between cursor-pointer"
        onClick={showModalHandler}
      >
        <div className="group w-full max-lg:max-w-xl border border-gray-300 rounded-lg">
          {n.thumbnailImage.src && (
            <div className="flex items-center">
              <img
                src={getApiUrl(n.thumbnailImage.src)}
                className="rounded-t-lg w-full object-cover"
                alt={`Titelbild zur News ${n.title}.`}
              />
            </div>
          )}
          <div className="p-4 lg:p-6 transition-all duration-300 rounded-b-2xl group-hover:bg-gray-50">
            <span className="text-primary font-medium mb-0 block">
              {dateformat(new Date(n.date), 'd.m.yyyy')}
            </span>
            <h4 className="text-xl text-gray-900 font-medium leading-8">
              {parse(n.title)}
            </h4>
            {n.text !== '' && (
              <>
                <div className="text-gray-500 mt-4 leading-6 mb-6 line-clamp-3">
                  {parse(
                    n.text
                      .replace(
                        /<a\s+([^>]*)>/g,
                        '<a $1 target="_blank" rel="noopener noreferrer">'
                      )
                      .replace(/<img[^>]*>/g, '')
                  )}
                </div>
                <span className="cursor-pointer text-primary text-base">
                  Mehr lesen...
                </span>
              </>
            )}
            {authCtx.isAdmin && !error && !props.skeleton && (
              <div className="mt-4 adminInfo">
                {user.username +
                  ' (' +
                  user.role +
                  ')' +
                  ', ' +
                  dateformat(new Date(n.editedAt), 'dd.mm.yyyy') +
                  ' um ' +
                  dateformat(new Date(n.editedAt), 'H:MM:ss') +
                  ' Uhr'}
                <div className="flex justify-between">
                  <button
                    type="button"
                    className="text-warning"
                    onClick={(e) => {
                      e.stopPropagation();
                      editNews();
                    }}
                  >
                    Bearbeiten
                  </button>
                  <br />
                  <button
                    type="button"
                    className="text-danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteNewsHandler();
                    }}
                  >
                    Entfernen
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Animation>
  );
}

// ADD NEWS WIDGET IN FRONT OF THE OTHERS
function NewNewsWidget(props) {
  const { showModal } = useContext(ModalContext);
  // show modal on click
  function showModalHandler() {
    showModal(
      'Neue News hochladen',
      null,
      <NewNewsForm patchNews={props.patchNews} />
    );
  }
  return (
    <div
      className={`group w-full h-[200px] max-lg:max-w-xl border border-gray-300 rounded-lg transition-all duration-300 hover:bg-gray-50 cursor-pointer flex flex-col justify-center items-center`}
      onClick={showModalHandler}
    >
      <FontAwesomeIcon icon={faPlusCircle} className={`addIcon`} />
      <span className={`mt-2`}>Hinzufügen</span>
    </div>
  );
}

const mousewheelOptions = {
  releaseOnEdges: true,
  forceToAxis: true,
  thresholdTime: 200,
  thresholdDelta: 20,
};

function NewsWidgets(props) {
  const [news, setNews] = useState([]);
  const authCtx = useContext(AuthContext);
  const { closeModal } = useContext(ModalContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { error, getAllNews } = useFetchContent();
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const brand = params.brand;
  const brandHouse = useBrandFind(brand, 'house');

  useEffect(() => {
    getAllNews((d) => {
      if (!error) {
        setNews(
          d.filter(
            (n) =>
              !n.comps ||
              n.comps.length === 0 ||
              (n.comps && n.comps.includes(brandHouse.NEWS_KEY))
          )
        );
        if (!loaded) setLoaded(true);
      } else if (error && authCtx.isAdmin)
        setPopupMessage('Fehler beim Laden der News', 'error');
    });
  }, [getAllNews, error, authCtx.isAdmin, setPopupMessage, loaded, brandHouse]);

  function patchNews(newNews) {
    let found = false;
    setNews((n) => {
      const updatedNews = n.map((newsItem) => {
        if (newsItem._id === newNews._id) {
          found = true;
          return newNews;
        } else {
          return newsItem;
        }
      });
      if (!found) {
        return [newNews, ...n];
      }
      return updatedNews;
    });
    closeModal();
  }

  if (!loaded || error) {
    return (
      <div>
        {props.header && (
          <h1
            className="sectionHeading"
            style={{ marginLeft: '20px', marginBottom: '20px' }}
          >
            Neuigkeiten
          </h1>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'none',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflow: 'hidden',
            width: '100%',
            maxWidth: 'var(--max-content-width)',
            margin: 0,
            padding: 0,
          }}
        >
          {Array.from({ length: 4 }).map((v, i) => {
            return (
              <div
                key={i}
                style={{
                  margin: '0 20px',
                  width: '240px',
                  maxWidth: '240px',
                  height: '300px',
                  backgroundColor: 'var(--light-contrast)',
                  borderRadius: '8px',
                  padding: '5px',
                  flexShrink: 0,
                }}
              >
                <Skeleton
                  variant="rounded"
                  width="80%"
                  height="40px"
                  className="mx-auto mb-2"
                />
                <Skeleton
                  variant="rounded"
                  width="40px"
                  height="10px"
                  className="mb-2 mr-0 ml-auto"
                />
                <Skeleton variant="rounded" height="100px" width="100%" />
                <br />
                {Array.from({ length: 7 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    width="100%"
                    height="10px"
                    className="mx-auto mb-1"
                  />
                ))}
              </div>
            );
          })}
        </div>
      </div>
    );
  } else if (news.length > 0 || authCtx.isAdmin) {
    return (
      <div
        className={classes.wrapper}
        style={{
          maxWidth: 'var(--max-content-width)',
          overflow: 'hidden',
        }}
      >
        {props.header && (
          <h1
            className="sectionHeading"
            style={{ marginLeft: '20px', marginBottom: '20px' }}
          >
            Neuigkeiten
          </h1>
        )}
        <Swiper
          slidesPerView={'auto'}
          className={`h-full noSelect`}
          modules={[Mousewheel, Scrollbar]}
          mousewheel={mousewheelOptions}
          centerInsufficientSlides={true}
          scrollbar={{ draggable: true }}
          style={{
            paddingBottom: '20px',
            marginBottom: authCtx.isAdmin && '20px',
          }}
        >
          {authCtx.isAdmin && (
            <SwiperSlide className={classes.swiperSlide}>
              <NewNewsWidget patchNews={patchNews} />
            </SwiperSlide>
          )}
          {news.map((item) => {
            return (
              <SwiperSlide
                style={{ marginLeft: '10px', marginRight: '10px' }}
                className={`${classes.swiperSlide} w-[280px] text-left`}
                key={item._id}
              >
                <NewsWidget data={item} patchNews={patchNews} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  }
}

export default NewsWidgets;
