import classes from './Service.module.css';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// IMPORT OWN COMPONENTS
import StaffWidgets from '../About/StaffWidgets';
import PageHeading from '../UI/PageHeading';
import Animation from '../UI/Animation';
import SingleText from '../UI/SingleText';
import Button from '../UI/Button';
import Products from '../About/Product';
import ServiceFeatures from '../About/ServiceFeatures';

// IMPORT MUI AND OTHER COMPONENTS
import { Paper, Tab } from '@mui/material/';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useBrandFind } from '../../hooks/useFinders';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import {
  MultiTextHeader,
  MultiTextTitle,
  MultiTextEntry,
  MultiTextAdminInfo,
  MultiTextWrapper,
  MultiTextDeleteIcon,
  MultiTextUrl,
  MultiTextStaff,
} from '../UI/MultiText';
import AuthContext from '../../store/auth-context';
import ModalContext from '../../store/modal-context';
import usePostContent from '../../hooks/usePostContent';
import PopupContext from '../../store/popup-context';
import { StyledTabs, TabPanel } from '../UI/TabController';
import { compData, globals } from '../../data/global-vars.js';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: '8px',
  color: theme.palette.text.secondary,
}));

function Service() {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal } = useContext(ModalContext);
  const { error, postMultiTextEntry } = usePostContent();
  const [editServiceleistungen, setEditServiceleistungen] = useState(false);

  const params = useParams();
  const brand = params.brand;
  const page = params.page;
  const brandObject = useBrandFind(brand);
  const brandHouse = useBrandFind(brand, 'house');

  const history = useHistory();
  const theme = useTheme();

  const emptyMultiText = {
    title: '',
    texts: [],
    editedBy: '',
    editedAt: '',
    createdBy: '',
    createdAt: '',
  };

  const [editMoreInfos, setEditMoreInfos] = useState(false);
  const [moreInfos, setMoreInfos] = useState(emptyMultiText);
  const [moreInfosLoaded, setMoreInfosLoaded] = useState(false);
  const [richText, setRichText] = useState(true);
  const [newMoreInfosTitle, setNewMoreInfosTitle] = useState('');
  const [newMoreInfosDepKey, setNewMoreInfosDepKey] = useState('');
  const [newMoreInfosText, setNewMoreInfosText] = useState('');
  const [newMoreInfosUrl, setNewMoreInfosUrl] = useState('');

  function submitNewMoreInfosHandler() {
    postMultiTextEntry(
      moreInfos._id,
      {
        title: newMoreInfosTitle,
        text: newMoreInfosText,
        url: newMoreInfosUrl,
        departmentKey: newMoreInfosDepKey,
      },
      (d) => {
        if (!error) {
          setPopupMessage(
            '"' + newMoreInfosTitle + '" wurde erfolgreich hinzugefügt!',
            'success'
          );
          setNewMoreInfosTitle('');
          setNewMoreInfosText('');
          setNewMoreInfosUrl('');
          setNewMoreInfosDepKey('');
          setMoreInfos(d);
        } else {
          setPopupMessage('Es gab einen Fehler beim Hochladen!', 'error');
        }
      }
    );
  }

  const [tabPanel, setTabPanel] = useState(
    authCtx.isAdmin || moreInfos.texts.length < 1 ? 0 : 1
  );
  const tabKey = params[0].split('/');
  useEffect(() => {
    if (moreInfos.texts.length > 0) {
      moreInfos.texts.map((i, _i) => {
        if (tabKey[0] === i.url) {
          return setTabPanel(_i + 1);
        }
        return null;
      });
      if (tabKey[0] === 'hinzufuegen') {
        setTabPanel(0);
        setEditMoreInfos(true);
      } else if (!moreInfos.texts.find((obj) => obj.url === tabKey[0])) {
        return setTabPanel(1);
      }
    }
  }, [tabPanel, tabKey, moreInfos]);

  function handleTabChange(event, index) {
    setTabPanel(index);
    const url = moreInfos.texts[index - 1]
      ? moreInfos.texts[index - 1].url
      : 'hinzufuegen';
    history.push(`/${brand}/${page}/` + url);
  }

  function handleChangeTabIndex(index) {
    setTabPanel(index);
    const url = moreInfos.texts[index - 1]
      ? moreInfos.texts[index - 1].url
      : 'hinzufuegen';
    history.push(`/${brand}/${page}/` + url);
  }

  return (
    <>
      <PageHeading title="Service">Service</PageHeading>
      <Grid container className="container" sx={{ padding: '0 20px' }}>
        <Grid
          xs={12}
          md={editServiceleistungen ? 12 : 5}
          id="serviceleistungen"
        >
          <ServiceFeatures
            setEditServiceleistungen={setEditServiceleistungen}
          />
        </Grid>
        {!editServiceleistungen && (
          <Grid
            xs={12}
            md={7}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              padding: '20px 0',
            }}
            className="w-full my-4 rounded-lg shadow-lg"
          >
            {/* <form
              onSubmit={submitHandler}
              style={{
                overflow: 'hidden',
                boxShadow: 'box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Item
                style={{
                  overflow: 'hidden',
                  maxWidth: '95%',
                  maxHeight: '95%',
                }}
              > */}
            <div className="grid grid-cols-12 w-full">
              <h1 className="subheading mx-2 col-span-12" id="termin-buchen">
                Service-Termin buchen
              </h1>{' '}
              {brandHouse === compData.SEYFARTH ? (
                <>
                  <a
                    href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DEU&dealer=75361&style=VW&tenant=vw"
                    target="noopener noreferrer"
                    className="col-span-3 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
                  >
                    <img
                      src={globals.BRANDS.VW.logo}
                      alt="Icon"
                      className="w-14"
                    />
                  </a>
                  <a
                    href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DEU&dealer=75361&style=VWN&tenant=vwn"
                    target="noopener noreferrer"
                    className="col-span-3 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
                  >
                    <img
                      src={globals.BRANDS.VW_NFZ.logo}
                      alt="Icon"
                      className="w-16"
                    />
                  </a>
                  <a
                    href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DEU&dealer=75361&style=AUDI&tenant=audi"
                    target="noopener noreferrer"
                    className="col-span-3 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
                  >
                    <img
                      src={globals.BRANDS.AUDI.logo}
                      alt="Icon"
                      className="w-16"
                    />
                  </a>
                  <a
                    href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DE&dealer=75361&kvps=DEU75361V&brand=F&brandCode=F&vehicleAddedInSBO=true&driver=false&style=DEFAULT&tenant=dxone"
                    target="noopener noreferrer"
                    className="col-span-3 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
                  >
                    <span>
                      Andere <br /> Marke
                    </span>
                  </a>
                </>
              ) : (
                <>
                  <a
                    href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DEU&dealer=57371&style=VW&tenant=vw"
                    target="noopener noreferrer"
                    className="col-span-6 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
                  >
                    <img
                      src={globals.BRANDS.SKODA.logo}
                      alt="Icon"
                      className="w-22"
                    />
                  </a>
                  <a
                    href="https://sbo.porscheinformatik.com/dxone/d/routing?country=DE&dealer=57371&kvps=DEU57371V&brand=F&brandCode=F&vehicleAddedInSBO=true&driver=false&style=DEFAULT&tenant=dxone"
                    target="noopener noreferrer"
                    className="col-span-6 mx-auto flex flex-col items-center justify-center w-24 h-24 bg-white  rounded-md hover:scale-[1.02] transition-all"
                  >
                    <span>
                      Andere <br /> Marke
                    </span>
                  </a>
                </>
              )}
            </div>
          </Grid>
        )}
      </Grid>
      <Grid
        xs={12}
        container
        className="container-contrast"
        sx={{
          paddingLeft: 'calc(20px + env(safe-area-inset-left))',
          paddingRight: 'calc(20px +  env(safe-area-inset-right))',
          margin: '20px 0',
        }}
      >
        <Grid
          xs={12}
          container
          className="container-contrast-inner"
          sx={{ padding: '20px', paddingBottom: '30px' }}
        >
          <h1 className="subheading" id="vermietung-und-nachruestung">
            Vermietung und Nachrüstung
          </h1>
          <Products />
        </Grid>
      </Grid>
      <Grid
        xs={12}
        className="container"
        id="waschanlage"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
          paddingTop: '20px',
        }}
      >
        <div
          style={{
            width: 'calc(100% - 20px)',
            maxWidth: '600px',
          }}
        >
          <Animation>
            <Item className={classes.moreInfosTextPanel}>
              <h1 className="subheading">
                <SingleText id="63e4134ffe838d336b0f4692" field="title" />
              </h1>
              <div
                className={`${classes.moreInfosTextPanelText}`}
                style={{ width: 'calc(100% - 20px)' }}
              >
                <SingleText id="63e4134ffe838d336b0f4692" field="text" />
              </div>

              <Button
                className={classes.btnSpace}
                maxWidth="200px"
                onClick={() => {
                  showModal(
                    <SingleText id="64379af365171cc7c96fcf97" field="title" />,
                    null,
                    <Animation dir="left">
                      <SingleText id="64379af365171cc7c96fcf97" field="text" />
                    </Animation>
                  );
                }}
              >
                mehr Infos
              </Button>
            </Item>
          </Animation>
        </div>
      </Grid>
      <Grid xs={12} className="container" sx={{ padding: '20px' }}>
        <MultiTextWrapper
          id="63ef5be3e7c01eb0c6772884"
          applyData={setMoreInfos}
          setEdit={setEditMoreInfos}
          value={moreInfos}
          setLoaded={setMoreInfosLoaded}
        >
          {moreInfosLoaded && (
            <>
              <Grid xs={12} container>
                <Grid xs={12}>
                  <h1 className="sectionHeading" id="mehr-informationen">
                    <MultiTextHeader
                      value={moreInfos}
                      setValue={setMoreInfos}
                      edit={editMoreInfos}
                      setEdit={setEditMoreInfos}
                      richText={richText}
                      setRichText={setRichText}
                    />
                  </h1>
                  <MultiTextAdminInfo value={moreInfos} />
                  <br />
                </Grid>
                <Grid xs={12}>
                  <StyledTabs
                    value={tabPanel}
                    onChange={handleTabChange}
                    indicatorColor={brandObject.primeColor}
                    className={classes.tabWrapper}
                  >
                    {authCtx.isAdmin && (
                      <Tab
                        label={<Button color="green">Hinzufügen</Button>}
                        onClick={() => {
                          history.push(`/${brand}/${page}/hinzufuegen`, {
                            scroll: false,
                          });
                          setTabPanel(0);
                        }}
                        value={0}
                        className={classes.tab}
                        style={{
                          padding: 0,
                          marginLeft: '10px',
                          marginRight: '10px',
                        }}
                      />
                    )}
                    {moreInfos.texts.map((i, _i) => {
                      return (
                        <Tab
                          label={i.title}
                          key={_i}
                          value={_i + 1}
                          className={classes.tab}
                          style={{
                            padding: 0,
                            marginLeft: '10px',
                            marginRight: '10px',
                          }}
                        />
                      );
                    })}
                  </StyledTabs>
                  <SwipeableViews
                    axis="x"
                    index={tabPanel}
                    onChangeIndex={handleChangeTabIndex}
                    enableMouseEvents={editMoreInfos ? false : true}
                  >
                    {authCtx.isAdmin ? (
                      <TabPanel value={0} index={0} dir={theme.direction}>
                        <div
                          className={`${classes.moreInfosTabPanelText} standardText`}
                          style={{ padding: '40px 0' }}
                        >
                          <MultiTextTitle
                            id={moreInfos._id}
                            value={newMoreInfosTitle}
                            setValue={setNewMoreInfosTitle}
                            setEdit={setEditMoreInfos}
                            edit={true}
                            new
                          />
                          <br />
                          <br />
                          <MultiTextUrl
                            id={moreInfos._id}
                            value={newMoreInfosUrl}
                            setValue={setNewMoreInfosUrl}
                            setEdit={setEditMoreInfos}
                            edit={true}
                            new
                          />
                          <br />
                          <br />
                          <MultiTextStaff
                            id={moreInfos._id}
                            value={newMoreInfosDepKey}
                            setValue={setNewMoreInfosDepKey}
                            setEdit={setEditMoreInfos}
                            edit={true}
                            new
                          />
                          <br />
                          <br />
                          <MultiTextEntry
                            id={moreInfos._id}
                            value={newMoreInfosText}
                            setValue={setNewMoreInfosText}
                            edit={true}
                            setEdit={setEditMoreInfos}
                            richText={richText}
                            new
                          />
                          <Button
                            maxWidth="200px"
                            color="green"
                            onClick={submitNewMoreInfosHandler}
                            icon={faCirclePlus}
                          >
                            Hochladen
                          </Button>
                        </div>
                      </TabPanel>
                    ) : (
                      <Fragment />
                    )}
                    {moreInfos.texts.map((p, i) => {
                      return (
                        <TabPanel
                          value={tabPanel}
                          index={i + 1}
                          dir={theme.direction}
                          key={i}
                        >
                          <div
                            className={`${classes.moreInfosTabPanelText} standardText`}
                            style={{
                              paddingBottom: '40px',
                              paddingTop: editMoreInfos && '40px',
                            }}
                          >
                            {authCtx.isAdmin && editMoreInfos && (
                              <Grid
                                container
                                xs={12}
                                sx={{
                                  textAlign: 'left',
                                }}
                                spacing={2}
                              >
                                <Grid xs={11}>
                                  <MultiTextTitle
                                    id={moreInfos._id}
                                    value={p}
                                    setValue={setMoreInfos}
                                    edit={editMoreInfos}
                                    setEdit={setEditMoreInfos}
                                  />
                                </Grid>
                                <Grid
                                  xs={1}
                                  sx={{
                                    textAlign: 'right',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <MultiTextDeleteIcon
                                    id={moreInfos._id}
                                    value={p}
                                    setValue={setMoreInfos}
                                    edit={editMoreInfos}
                                  />
                                </Grid>
                                <Grid xs={12}>
                                  <MultiTextUrl
                                    id={moreInfos._id}
                                    value={p}
                                    setValue={setMoreInfos}
                                    edit={editMoreInfos}
                                    setEdit={setEditMoreInfos}
                                  />
                                </Grid>
                                <Grid xs={12}>
                                  <MultiTextStaff
                                    id={moreInfos._id}
                                    value={p}
                                    setValue={setMoreInfos}
                                    edit={editMoreInfos}
                                    setEdit={setEditMoreInfos}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            <br />
                            <MultiTextEntry
                              id={moreInfos._id}
                              value={p}
                              setValue={setMoreInfos}
                              edit={editMoreInfos}
                              setEdit={setEditMoreInfos}
                              richText={richText}
                            />
                            {p.departmentKey && (
                              <div style={{ paddingTop: '20px' }}>
                                <StaffWidgets
                                  dep={p.departmentKey}
                                  noBackground
                                />
                              </div>
                            )}
                          </div>
                        </TabPanel>
                      );
                    })}
                  </SwipeableViews>
                </Grid>
              </Grid>
            </>
          )}
        </MultiTextWrapper>
      </Grid>
      <div className="container" style={{ marginBottom: '30px' }}>
        <StaffWidgets dep="service" />
      </div>
    </>
  );
}

export default Service;
