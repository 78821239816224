function DateTimeInput(props) {
  const {
    value,
    onChange,
    label,
    required,
    error,
    name,
    disablePast = false,
    disableFuture = false,
    className,
  } = props;

  const handleChange = (event) => {
    onChange(event);
  };

  const now = new Date().toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:mm

  const min = disablePast ? now : undefined;
  const max = disableFuture ? now : undefined;

  const isEmpty = !value;

  return (
    <div className={className}>
      <div className="cursor-text relative text-left h-[58px]">
        <input
          type="datetime-local"
          value={value || ''}
          id={`datetime-input-${name}`}
          name={name}
          onChange={handleChange}
          required={required}
          min={min}
          max={max}
          className="cursor-text block px-2.5 pb-2.5 pt-4 w-full h-full text-sm text-gray-900 bg-transparent rounded border-[1px] border-gray-300 appearance-none peer focus:border-[var(--text-color)] focus:outline-none"
        />
        <label
          htmlFor={`datetime-input-${name}`}
          className={`bg-white cursor-text pointer-events-none absolute text-gray-500 duration-200 transform z-10 origin-[0] px-2 start-1
            ${
              isEmpty
                ? 'top-1/2 -translate-y-1/2 scale-100'
                : 'top-1 -translate-y-4 scale-75'
            }
            peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:text-[var(--vw-color)]`}
        >
          {label} {required && '*'}
        </label>
      </div>
      {error && (
        <div className="text-red-500 text-left w-full text-sm">{error}</div>
      )}
    </div>
  );
}

export default DateTimeInput;
