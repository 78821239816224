function SelectInput(props) {
  const { value, onChange, label, required, error, name, options = [] } = props;

  const handleChange = (event) => {
    onChange(event);
  };

  const isEmpty = !value;

  return (
    <div className={`${props.className} cursor-pointer`}>
      <div className="relative text-left h-[58px]">
        <select
          value={value || ''}
          id={`select-${name}`}
          className="block cursor-pointer px-2.5 pb-2.5 pt-4 w-full h-full text-sm text-gray-900 bg-transparent rounded border-[1px] border-gray-300 appearance-none peer focus:border-[var(--text-color)] focus:outline-none"
          name={name}
          onChange={handleChange}
          required={required}
        >
          <option value="" disabled hidden></option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        {/* Label */}
        <label
          htmlFor={`select-${name}`}
          className={`bg-white cursor-pointer pointer-events-none absolute text-gray-500 duration-200 transform z-10 origin-[0] px-2 start-1
            ${
              isEmpty
                ? 'top-1/2 -translate-y-1/2 scale-100'
                : 'top-1 -translate-y-4 scale-75'
            }
            peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:text-[var(--vw-color)]
          `}
        >
          {label} {required && '*'}
        </label>

        {/* Chevron Icon */}
        <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center">
          <svg
            className="w-4 h-4 text-gray-500"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
          >
            <path d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>

      {error && (
        <div className="text-red-500 text-left w-full text-sm">{error}</div>
      )}
    </div>
  );
}

export default SelectInput;
