import classes from './StaffWidgets.module.css';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useBrandFind } from '../../hooks/useFinders';
import ModalContext from '../../store/modal-context';

// IMPORT OWN COMPONENTS
import Animation from '../UI/Animation';

// IMPORT DATA
import { globals } from '../../data/global-vars';
import { getApiUrl } from '../../config/api';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Mousewheel } from 'swiper';

// IMPORT MUI COMPONENTS
import {
  faAt,
  faCancel,
  faCirclePlus,
  faEye,
  faEyeSlash,
  faList,
  faPencil,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../UI/Button';
import usePostContent from '../../hooks/usePostContent';
import usePatchContent from '../../hooks/usePatchContent';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import { Skeleton, Switch, ListItemAvatar, ListItemText } from '@mui/material';
import PopupContext from '../../store/popup-context';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import useFetchContent from '../../hooks/useFetchContent';
import useDeleteContent from '../../hooks/useDeleteContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthContext from '../../store/auth-context';
import DraggableList, { DraggableListItem } from '../UI/DraggableList';
import PhoneInput from '../UI/Inputs/PhoneInput';
import TextInput from '../UI/Inputs/TextInput';
import EmailInput from '../UI/Inputs/EmailInput';
import FileInput from '../UI/Inputs/FileInput';

// error message for adding a new employee
const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

const requestTypes = [
  { key: 'workshop', title: 'Werkstatt' },
  { key: 'service', title: 'Service' },
  { key: 'sales', title: 'Verkauf' },
  { key: 'products', title: 'Produkte' },
  { key: 'assessment', title: 'Personal' },
  { key: 'general', title: 'Allgemeines' },
];

function StaffModalForm(props) {
  const allDepartments = props.allDepartments;
  const { isLoading, error, postEmployee, postEmployeeThumbnail } =
    usePostContent();
  const { error: updateError, patchEmployee } = usePatchContent();
  const s = props.selected;
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  const { setPopupMessage } = useContext(PopupContext);

  const [add, setAdd] = useState(s.edit ? false : true);
  const [firstName, setFirstName] = useState(s.firstName ? s.firstName : '');
  const [lastName, setLastName] = useState(s.lastName ? s.lastName : '');
  const [birthDate, setBirthDate] = useState(
    dayjs(s.birthDate ? s.birthDate : new Date())
  );
  const [description, setDescription] = useState(
    s.description ? s.description : ''
  );
  const [mail, setMail] = useState(s.mail ? s.mail : '');
  const [phone, setPhone] = useState(s.phone ? s.phone : '');
  const [selDepartments, setSelDepartments] = useState(
    s.departments ? s.departments : []
  );
  const [selRequests, setSelRequests] = useState(
    s.assignedRequests ? s.assignedRequests : []
  );
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const { closeModal } = useContext(ModalContext);

  function toggleDep(_id) {
    const newArray = selDepartments.filter((item) => item !== _id);
    if (newArray.length === selDepartments.length) {
      setSelDepartments((prevDepartments) => [...prevDepartments, _id]);
    } else {
      setSelDepartments(newArray);
    }
  }

  function toggleReqType(key) {
    const newArray = selRequests.filter((item) => item !== key);
    if (newArray.length === selRequests.length) {
      setSelRequests((prevRequests) => [...prevRequests, key]);
    } else {
      setSelRequests(newArray);
    }
  }

  useEffect(() => {
    setFirstName(s.firstName ? s.firstName : '');
    setLastName(s.lastName ? s.lastName : '');
    setBirthDate(dayjs(s.birthDate ? s.birthDate : new Date()));
    setDescription(s.description ? s.description : '');
    setMail(s.mail ? s.mail : '');
    setPhone(s.phone ? s.phone : '');
    setAdd(s.edit ? false : true);
    setSelDepartments(s.departments ? s.departments : []);
    setSelRequests(s.assignedRequests ? s.assignedRequests : []);
  }, [s]);

  function handleSubmit(e) {
    e.preventDefault();
    const employee = {
      firstName,
      lastName,
      description,
      birthDate,
      mail,
      phone,
      assignedRequests: selRequests,
      departments: selDepartments,
    };
    if (add) {
      postEmployee(employee, (d) => {
        if (thumbnailImage !== null) {
          const formData = new FormData();
          formData.append('upload', thumbnailImage);
          postEmployeeThumbnail(formData, d._id, (i) => {
            props.patchEmployees(
              { ...d, thumbnailImage: { src: i.url } },
              'add'
            );
            setPopupMessage(
              'Mitarbeiter: "' +
                d.firstName +
                ' ' +
                d.lastName +
                '" hinzugefügt.',
              'success'
            );
          });
        } else {
          props.patchEmployees(d, 'add');
        }
        closeModal();
      });
    } else {
      patchEmployee(s._id, employee, (d) => {
        if (thumbnailImage) {
          const formData = new FormData();
          formData.append('upload', thumbnailImage);
          postEmployeeThumbnail(formData, d._id, (i) => {
            props.patchEmployees(
              { ...d, thumbnailImage: { src: i.url } },
              'edit'
            );
            setPopupMessage('Mitarbeiter erfolgreich bearbeitet!', 'success');
          });
        } else {
          props.patchEmployees(d, 'edit');
        }
        closeModal();
      });
    }
  }

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PageLoaderIcon isLoading={isLoading} />
      </div>
    );
  } else
    return (
      <form
        style={{
          width: '100%',
          marginBottom: '20px',
        }}
        onSubmit={handleSubmit}
      >
        <p className="mb-4">
          Die hier erstellten Mitarbeiter werden für die Darstellung auf der
          Website verwendet.
        </p>
        {error || (updateError && errorMessage)}
        <div className="grid grid-cols-12 gap-4">
          <TextInput
            label="Vorname"
            className="col-span-12 md:col-span-6"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextInput
            label="Nachname"
            className="col-span-12 md:col-span-6"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <TextInput
            label="Beschreibung der Tätigkeit"
            className="col-span-12 md:col-span-6"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <EmailInput
            label="E-Mail-Adresse"
            className="col-span-12 md:col-span-6"
            name="mail"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
          <PhoneInput
            label="Telefonnummer"
            className="col-span-12 md:col-span-6"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <FileInput
            types={fileTypes}
            handleChange={setThumbnailImage}
            label={
              !thumbnailImage
                ? 'Kleines Bild hinzufügen'
                : 'Kleines Bild ausgewählt'
            }
            className="col-span-12 md:col-span-6"
            name="thumbnailImage"
            value={thumbnailImage}
          />
          {allDepartments.map((dd, ii) => {
            return (
              <div className="col-span-12" key={ii}>
                <div className="w-full text-primary font-semibold text-lg">
                  {dd.brandTitle}
                </div>
                <div className="flex flex-wrap">
                  {dd.departments.map((d, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          border: selDepartments.includes(d._id)
                            ? '1px solid ' + dd.primeColor
                            : '1px solid var(--text-color)',
                          color: selDepartments.includes(d._id)
                            ? 'white'
                            : 'var(--text-color)',
                          backgroundColor: selDepartments.includes(d._id)
                            ? dd.primeColor
                            : 'white',
                        }}
                        className={`noSelect cursor-pointer w-fit p-1 transition-all duration-100 ease-in-out rounded-lg m-[5px] border text-sm`}
                        onClick={() => {
                          toggleDep(d._id);
                        }}
                      >
                        {d.title}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="col-span-12 flex flex-wrap">
            <div className="w-full text-primary font-semibold text-lg">
              Anfragetypen
            </div>
            {requestTypes.map((t, i) => {
              return (
                <div
                  key={i}
                  style={{
                    border: selRequests.includes(t.key)
                      ? '1px solid ' + globals.BRANDS.VW.primeColor
                      : '1px solid var(--text-color)',
                    color: selRequests.includes(t.key)
                      ? 'white'
                      : 'var(--text-color)',
                    backgroundColor: selRequests.includes(t.key)
                      ? globals.BRANDS.VW.primeColor
                      : 'white',
                  }}
                  className={`noSelect cursor-pointer w-fit p-1 transition-all duration-100 ease-in-out rounded-lg m-[5px] border text-sm`}
                  onClick={() => {
                    toggleReqType(t.key);
                  }}
                >
                  {t.title}
                </div>
              );
            })}
          </div>
          <div className="col-span-12 flex justify-end">
            <Button
              color="green"
              icon={add ? faCirclePlus : faPencil}
              type="submit"
              disabled={firstName === '' || lastName === '' ? true : false}
              maxWidth="200px"
            >
              {add ? 'Hinzufügen' : 'Aktualisieren'}
            </Button>
          </div>
        </div>
      </form>
    );
}

function EmployeeLine(props) {
  const e = props.data;
  const { showModal } = useContext(ModalContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { error: deleteError, deleteEmployee } = useDeleteContent();
  const { patchEmployee } = usePatchContent();
  const [contacter, setContacter] = useState(e.contact || false);
  const [isActive, setIsActive] = useState(e.active || false);

  function toggleContacterHandler() {
    setContacter((c) => {
      patchEmployee(e._id, { contact: !c }, () => {});
      setPopupMessage(
        `Abteilung: ${e.firstName} ${e.lastName} ${
          c ? 'ausgeblendet' : 'eingeblendet'
        }.`,
        'success'
      );
      return !c;
    });
  }

  function toggleIsActiveHandler() {
    patchEmployee(
      e._id,
      { active: !isActive, contact: isActive ? false : contacter },
      (d) => {
        setPopupMessage(
          `Abteilung: ${e.firstName} ${e.lastName} ${
            !isActive ? 'aktiviert' : 'deaktiviert'
          }.`,
          'success'
        );
        setIsActive((a) => !a);
        setContacter((c) => {
          if (isActive) {
            return false;
          } else {
            return c;
          }
        });
      }
    );
  }

  function deleteEmployeeHandler() {
    const fullName = `${e.firstName} ${e.lastName}`;
    const confirmed = window.confirm(
      `Mitarbeiter "${fullName}" wirklich löschen?`
    );

    if (!confirmed) return;

    deleteEmployee(e._id, (d) => {
      if (!deleteError) {
        setPopupMessage(`Mitarbeiter "${fullName}" gelöscht.`, 'success');
        props.patchEmployees(d, 'delete');
      } else {
        setPopupMessage('Fehler beim Löschen des Mitarbeiters!', 'error');
      }
    });
  }

  return (
    <div
      className={`grid grid-cols-12 justify-center items-center h-[100%] transition-all duration-300 rounded-lg border-[1px] p-4 ${
        isActive && 'bg-[var(--light-contrast)]'
      } ${
        contacter
          ? 'border-[var(--vw-color)]'
          : 'border-[var(--light-contrast)]'
      }`}
    >
      <div className="col-span-12 flex justify-end items-center gap-x-2">
        <FontAwesomeIcon
          title={!contacter ? 'Einblenden' : 'Ausblenden'}
          icon={contacter ? faEye : faEyeSlash}
          className={`cursor-pointer w-[20px] ${
            contacter ? 'text-primary' : 'text-gray-400'
          }`}
          onClick={toggleContacterHandler}
        />
        <FontAwesomeIcon
          icon={faPencil}
          className="manageColor"
          onClick={() => {
            showModal(
              'Mitarbeiter hinzufügen',
              null,
              <StaffModalForm
                selected={{
                  ...e,
                  departments: e.departments?.map((d) => d._id),
                  edit: true,
                }}
                allDepartments={props.allDepartments}
                patchEmployees={props.patchEmployees}
              />
            );
          }}
        />
        <Switch
          className={classes.archiveSwitch}
          checked={isActive}
          onChange={toggleIsActiveHandler}
        />
        <FontAwesomeIcon
          icon={faCancel}
          className="cancelColor"
          onClick={deleteEmployeeHandler}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div className="col-span-12 flex flex-row justify-center items-center">
        <img
          src={e.thumbnailImage ? getApiUrl(e.thumbnailImage.src) : null}
          className={`rounded-full object-cover m-auto shadow-xl transition-all w-[130px] h-[130px] mr-8`}
          width="130px"
          height="130px"
          alt="Nahaufnahme Mitarbeiter"
          loading="lazy"
        />
        <div className="w-full grid grid-cols-12 text-left">
          <div className="col-span-12 text-primary font-semibold text-lg">
            {e.lastName + ', ' + e.firstName}
          </div>

          <div className="col-span-12">{e.description}</div>
          <div className="col-span-12">
            <FontAwesomeIcon icon={faPhone} className="mr-2" />
            {e.phone ? e.phone : 'Nicht eingetragen.'}
          </div>
          <div className="col-span-12">
            <FontAwesomeIcon icon={faAt} className="mr-2" />
            {e.mail ? e.mail : 'Nicht eingetragen.'}
          </div>
          <div className="col-span-12 adminInfo">
            {e.departments?.map((v, i) => {
              if (i !== e.departments?.length - 1)
                return v.title + ' (' + v.brand + '), ';
              else return v.title + ' (' + v.brand + ')';
            })}
          </div>
          <div className="col-span-12 adminInfo">
            {e.assignedRequests?.length > 0 && (
              <div>
                Zugewiesene Anfragen:{' '}
                {e.assignedRequests
                  .map((r) => requestTypes.find((obj) => obj.key === r).title)
                  .join(', ')}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function EmployeeList(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const { showModal } = useContext(ModalContext);

  const filteredEmployees = props.employees.filter((e) =>
    `${e.firstName} ${e.lastName}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const showAddForm = () => {
    showModal(
      'Mitarbeiter hinzufügen',
      null,
      <StaffModalForm
        selected={{}}
        allDepartments={props.allDepartments}
        patchEmployees={props.patchEmployees}
      />
    );
  };

  return (
    <div className="grid grid-cols-12 relative overflow-hidden bg-white border-lightgray border-[1px] rounded-lg gap-4">
      <div className="col-span-12 px-2 py-4 grid grid-cols-12 gap-2 border-b border-b-lightgray">
        <div className="order-2 md:order-1 col-span-12 md:col-span-6">
          <form className="flex items-center">
            <label className="sr-only">Suchen</label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    rillrole="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    cliprole="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2 outline-none"
                placeholder="Suchen"
                required=""
              />
            </div>
          </form>
        </div>
        <div className="order-1 md:order-2 col-span-12 md:col-span-6 flex justify-end gap-x-2">
          <button
            className="col-span-4 md:col-span-2 flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-green-700 bg-white border border-green-700 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
            onClick={showAddForm}
          >
            + Hinzufügen
          </button>
        </div>
      </div>
      <div className="grid grid-cols-12 col-span-12 gap-4 p-4 max-h-[600px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 border-b border-b-lightgray">
        {filteredEmployees.map((e, i) => (
          <div key={e._id || i} className="col-span-12 lg:col-span-6">
            <EmployeeLine
              data={e}
              setSelected={props.setSelected}
              selected={props.selected}
              patchEmployees={props.patchEmployees}
              allDepartments={props.allDepartments}
            />
          </div>
        ))}
        {filteredEmployees.length < 1 && (
          <div
            className="col-span-12 flex items-center justify-center text-gray-500"
            style={{ height: '100%' }}
          >
            <p className="text-sm">Keine Mitarbeiter gefunden.</p>
          </div>
        )}
      </div>
      <div className="col-span-12 flex items-center justify-between bg-white px-4 py-3 sm:px-6">
        <div className="flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              <span className="font-medium">
                {props.employees.length + ' '}
              </span>
              Mitarbeiter
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function StaffWidget(props) {
  const p = props.data;

  return (
    <div
      className={`${classes.container}`}
      style={{
        minHeight: props.height,
      }}
    >
      <Animation dir="scaleIn">
        {props.skeleton ? (
          <Skeleton
            variant="circular"
            width="130px"
            height="130px"
            sx={{
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        ) : (
          <img
            src={p.thumbnailImage ? getApiUrl(p.thumbnailImage.src) : null}
            className={`rounded-full mx-auto my-2 shadow-xl transition-all object-cover w-[130px] h-[130px]`}
            alt={'Nahaufnahme von ' + p.firstName + ' ' + p.lastName}
            width="130px"
            height="130px"
            loading="lazy"
          />
        )}
      </Animation>

      {props.skeleton ? (
        <Fragment>
          <br />
          <Skeleton variant="rounded" width="80px" height="80px" />
          <br />
          <Skeleton variant="rounded" width="120px" height="60px" />
        </Fragment>
      ) : (
        <Fragment>
          <div style={{ marginBottom: '20px' }}>
            <span
              className={`${classes.name} text-wrapping`}
              style={{ color: props.primeColor }}
            >
              {p.firstName + ' ' + p.lastName}
            </span>
            <br />
            {!props.short && (
              <span className={`${classes.description}`}>{p.description}</span>
            )}
          </div>
          {props.short && (p.phone !== '' || p.mail !== '') && (
            <div>
              {p.phone !== '' && (
                <a
                  href={`tel:${p.phone
                    .replace(/\D/g, '')
                    .replace(/^0/, '+49')}`}
                  style={{ color: 'var(--text-color)' }}
                >
                  {p.phone}
                </a>
              )}
              <br />
              <a href={'mailto:' + p.mail} style={{ color: 'var(--vw-color)' }}>
                <span>{p.mail.match(/^([^@]+)@(.+)$/)[1]}</span>
                <br />
                <span style={{ fontSize: '12px' }}>
                  @{p.mail.match(/^([^@]+)@(.+)$/)[2]}
                </span>
              </a>
            </div>
          )}
          {!props.short && (p.phone !== '' || p.mail !== '') && (
            <div className={classes.contactInfoShort}>
              {p.phone !== '' && (
                <a
                  href={`tel:${p.phone
                    .replace(/\D/g, '')
                    .replace(/^0/, '+49')}`}
                  style={{ color: 'var(--text-color)' }}
                >
                  {p.phone}
                </a>
              )}
              <br />
              <a href={'mailto:' + p.mail} style={{ color: 'var(--vw-color)' }}>
                <span>{p.mail.match(/^([^@]+)@(.+)$/)[1]}</span>
                <br />
                <span style={{ fontSize: '12px' }}>
                  @{p.mail.match(/^([^@]+)@(.+)$/)[2]}
                </span>
              </a>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

function SortStaffList(props) {
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const primeColor = brandObject.primeColor;
  // declare data vars
  const { setPopupMessage } = useContext(PopupContext);
  const [items, setItems] = useState(props.employees);

  const { error, sortMembersInDepartments } = usePatchContent();

  const { patchEmployee } = usePatchContent();

  // function toggleContacterHandler(item) {
  //   patchEmployee(item._id, { ...item, contact: !item.contact }, (d) => {
  //     setItems((prevItems) => {
  //       const updatedItems = prevItems.map((el) => {
  //         if (el._id === item._id) {
  //           return { ...el, ...d };
  //         }
  //         return el;
  //       });
  //       return updatedItems;
  //     });
  //   });
  // }

  useEffect(() => {
    props.setEmployees(items);
  }, [items]);

  // sortItems
  function sortItems(arr, droppableIndex, draggableIndex) {
    setItems((items) => {
      if (droppableIndex === draggableIndex) {
        setPopupMessage('Keine Änderung.', 'warning');
        return items;
      } else {
        sortMembersInDepartments(
          props.depId,
          arr[droppableIndex]._id,
          droppableIndex === 0 ? 0 : arr[droppableIndex - 1]._id,
          (d) => {
            if (!error) {
              setPopupMessage('Mitarbeiter neu sortiert.', 'success');
            } else {
              setPopupMessage('Fehler bei der Sortierung.', 'error');
            }
          }
        );
        return arr;
      }
    });
  }

  return (
    <DraggableList
      items={items}
      setItems={sortItems}
      className="py-2"
      paperClassName="py-2"
    >
      {items.map((item, index) => {
        return (
          <DraggableListItem
            item={item}
            index={index}
            key={index.toString()}
            id={index.toString()}
          >
            <div
              style={{
                width: '100%',
                backgroundColor: !item.contact && 'var(--light-contrast)',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '10px',
              }}
            >
              <ListItemAvatar
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: '10px',
                }}
              >
                <img
                  src={
                    item.thumbnailImage
                      ? getApiUrl(item.thumbnailImage.src)
                      : null
                  }
                  alt={
                    'Nahaufnahme von ' + item.firstName + ' ' + item.lastName
                  }
                  width="50px"
                  height="50px"
                  className="rounded-full w-[50px] h-[50px]"
                  loading="lazy"
                  style={{
                    borderRadius: '50%',
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={item.lastName + ', ' + item.firstName}
                secondary={item.description}
              />
            </div>
          </DraggableListItem>
        );
      })}
    </DraggableList>
  );
}

const mousewheelOptions = {
  releaseOnEdges: true,
  forceToAxis: true,
  thresholdTime: 200,
  thresholdDelta: 20,
};

function StaffWidgets(props) {
  const params = useParams();
  const authCtx = useContext(AuthContext);
  const brand = params.brand;
  const brandObject = useBrandFind(props.brand ? props.brand : brand);
  const primeColor = brandObject.primeColor;
  const {
    isLoading,
    error,
    getSingleDepartmentByBrand,
    getAllDepartmentsOfBrand,
  } = useFetchContent();
  const { showModal } = useContext(ModalContext);
  const [isLoaded, setIsLoaded] = useState(false);

  const [department, setDepartment] = useState({ _id: '' });
  const [employees, setEmployees] = useState(props.persons || []);

  useEffect(() => {
    if (!props.persons) {
      getSingleDepartmentByBrand(
        brandObject ? brandObject.make : 'VW',
        props.dep,
        (d) => {
          setEmployees(d);
          setIsLoaded(true);
        }
      );
    }
  }, [brand]);

  useEffect(() => {
    if (error) {
      setEmployees([]);
      setIsLoaded(true);
    }
  }, [error]);

  useEffect(() => {
    getAllDepartmentsOfBrand(brandObject ? brandObject.make : 'VW', (d) => {
      if (employees.length > 1)
        setDepartment(d.find((obj) => obj.key === props.dep));
    });
  }, [brand, employees]);

  const [swipeHeight, setSwipeHeight] = useState(0);

  const sliderRef = useRef(null);
  const sliderSwipeRef = useRef(null);

  useEffect(() => {
    const resizeSliderSwipe = () => {
      const sliderHeight = sliderRef.current.clientHeight;
      setSwipeHeight(`${sliderHeight - 20}px`);
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Element ist sichtbar, Höhe anpassen
          resizeSliderSwipe();
        }
      });
    });

    observer.observe(sliderRef.current);

    // Aufräumen, wenn das Komponenten-Unmounted wird
    return () => {
      observer.unobserve(sliderRef.current);
    };
  }, []);

  function sortStaffHandler() {
    showModal(
      'Sortieren',
      brandObject
        ? brandObject.make + ' ' + department.title
        : 'VW ' + department.title,
      <SortStaffList
        employees={employees}
        setEmployees={setEmployees}
        depId={department._id}
      />
    );
  }

  if (props.grid) {
  } else
    return (
      <div className="flex justify-center items-center w-[100%] h-[100%]">
        <div
          className={`rounded-lg flex justify-center items-center flex-col`}
          style={{
            backgroundColor:
              props.noBackground ||
              employees.filter((e) => e.contact || props.all).length === 0
                ? 'transparent'
                : 'var(--light-contrast)',
            paddingBottom:
              authCtx.isAdmin && employees.length >= 1 && isLoaded ? '20px' : 0,
            width: props.noBackground ? '100%' : 'calc(100% - 20px)',
          }}
        >
          {!isLoading && employees.length >= 1 && props.showBrandTitle && (
            <h2 style={{ color: brandObject.primeColor }}>
              {brandObject.name}
            </h2>
          )}
          <Swiper
            slidesPerView={'auto'}
            grabCursor={true}
            mousewheel={mousewheelOptions}
            className={`${classes.swiper} noSelect`}
            modules={[Mousewheel]}
            centerInsufficientSlides={true}
            ref={sliderRef}
          >
            {(isLoading || employees.length < 1) && !isLoaded ? (
              <SwiperSlide className={classes.swiperSlide}>
                <StaffWidget skeleton />
                <StaffWidget skeleton />
              </SwiperSlide>
            ) : (
              employees.map((e, i) => {
                if (e.contact || props.all) {
                  return (
                    <SwiperSlide
                      className={classes.swiperSlide}
                      key={i}
                      ref={sliderSwipeRef}
                      style={{
                        minHeight: swipeHeight,
                      }}
                    >
                      <StaffWidget
                        data={e}
                        primeColor={primeColor}
                        height={swipeHeight}
                      />
                    </SwiperSlide>
                  );
                }
              })
            )}
          </Swiper>
          {employees.length >= 1 && authCtx.isAdmin && (
            <Button maxWidth="200px" icon={faList} onClick={sortStaffHandler}>
              Sortieren
            </Button>
          )}
        </div>
      </div>
    );
}

export default StaffWidgets;
